<template>
  <div class="asset-list">
    <Warning
      v-if="showWarningMessage"
      icon="notifications"
      type="info"
      classes="asset-list__notification"
    >
      O relatório está sendo gerado e em breve estará disponível na página de Relatórios,
      <router-link
        to="/files/reports"
      >
        clique aqui para ir
      </router-link>.
    </Warning>

    <BaseContainer
      :active-spacer="true"
      :permission-list="['buyer', 'seller', 'owner']"
    >
      <template v-slot:header-title>
        {{ headerTitle }}
      </template>
      <template v-slot:header-title-description>
        {{ headerTitleDescription }}
      </template>

      <div
        class="asset-list__header__actions"
        :class="{ 'app-apply-blur' : openSideAssetsCollection || openModalAssetDetail }"
      >
        <BaseFilters
          scope="assets"
          filter-scope="asset"
          :pagination="pagination"
          :load-page="loadPage"
          :is-admin-editing="!openModalAssetDetail && isAdminEditing"
          @executeActionFilter="filterAssets"
        />
        <v-spacer />
        <v-tooltip
          bottom
          :disabled="!disableExportData"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 text-decoration-underline text-uppercase"
                :class="{ 'app-apply-blur' : isAdminEditing }"
                button-class="app-font-regular"
                :is-disabled="disableExportData"
                @click="executeExportData"
              >
                Gerar Relatório
              </BaseButton>
            </span>
          </template>
          Selecione um intervalo de até 90 dias
        </v-tooltip>
      </div>
      <BaseAlert :class="{ 'app-apply-blur' : isAdminEditing }">
        <b>Filtro aplicado:</b> {{ filterAlert }}
      </BaseAlert>
      <template v-slot:header-actions>
        <div :class="{ 'app-apply-blur' : isAdminEditing }">
          <div
            v-if="false"
            class="color--text-black-054"
          >
            <p class="font-weight-bold text-caption">
              Valor disponível
            </p>
            <p
              style="line-height: 30px;"
              class="font-weight-bold text-h4"
            >
              {{ availableAmount | formatCurrency }}
            </p>
          </div>
          <v-chip
            v-show="programDraftMovementId"
            color="#EBECF0"
            :class="{'animate-label-cart' : !showAssetsCollection }"
            class="primary--text asset-list__label-cart font-weight-bold"
            @click="openAssetsCollection"
          >
            <v-icon
              class="primary--text ml-1"
              style="font-size: 24px;"
            >
              chevron_left
            </v-icon>
            <v-icon
              class="primary--text mx-2"
              style="font-size: 16px;"
            >
              storefront
            </v-icon>
            Negociação pendente
          </v-chip>
        </div>
      </template>
      <BaseDataTable
        :table-model="selected"
        :headers="tableAssetHeaders"
        background-color="white"
        :hide-headers="true"
        :items="dataAssetList"
        item-key="id"
        classes="elevation-2 mt-6"
        :class="{ 'app-apply-blur' : isAdminEditing }"
        :is-admin-editing="isAdminEditing"
        :total-items="assetListTotalElements"
        :options="pagination"
        @update-pagination="updatePagination"
        @update-model="updateModel"
      >
        <template v-slot:inject-header="{headers}">
          <tr :class="{ 'app-apply-blur' : openSideAssetsCollection }">
            <th
              v-if="isSeller()"
              class="check"
            >
              <v-checkbox
                :input-value="headers.props.everyItem"
                :indeterminate="headers.props.indeterminate"
                :disabled="headers.props.everyItem || loadPage"
                primary
                hide-details
                @click.stop="toggleAll(headers.props.someItems)"
              />
            </th>
            <template
              v-for="(header, index) in headers.props.headers"
            >
              <th
                v-if="header.value != 'admin-config'"
                :key="index"
                style="white-space: nowrap;"
                :class="[filterData.pagination.sort_order, header.value === filterData.pagination.sort_by ? 'active' : '', header.class, isSortable(header) ? 'header-sortable' : '']"
                @click="isSortable(header) ? changeSort(header.value) : null"
              >
                {{ translateRoleType(header.text) }}
                <v-icon
                  v-if="isSortable(header)"
                  class="v-data-table-header__icon"
                  small
                >
                  arrow_upward
                </v-icon>
              </th>
              <th
                v-if="header.value == 'admin-config'"
                :key="index"
                class="text-right"
              />
            </template>
          </tr>
        </template>

        <template
          v-slot:inject-items="scopedItems"
        >
          <tr
            v-for="props in scopedItems"
            :key="props.index"
            :class="{ 'app-apply-blur' : isAdminEditing }"
          >
            <td
              v-if="isSeller()"
              class="check"
            >
              <v-simple-checkbox
                :value="props.isSelected"
                :disabled="props.item.disabled || props.isSelected || loadPage"
                color="primary"
                @input="props.select(!props.isSelected)"
                @[preventClickOnCheckbox(props)]="addOrDeleteAssetDraftMovement(props.isSelected, props.item)"
              />
            </td>

            <template
              v-for="header, index in tableAssetHeaders"
            >
              <td
                v-if="header.value !== 'admin-config'"
                :key="index"
                class="text-left"
              >
                <div
                  v-if="header.hidden === 'true' && hideAllData"
                >
                  {{ hideData(props.item[header.value], header.hidden) }}
                </div>
                <div
                  v-else-if="header.value === 'status.name'"
                >
                  <v-chip
                    class="asset-list__item--status font-weight-medium"
                    :color="convertStatus(props.item[splitProp(header.value)[0]].category)"
                  >
                    {{ props.item[splitProp(header.value)[0]].name }}
                  </v-chip>
                </div>

                <div v-else-if="header['input_type'] === 'date'">
                  {{ props.item[header.value] | getDate }}
                </div>

                <div v-else-if="header.value === 'type.title'">
                  <BaseTextTooltip
                    :text-item="props.item[splitProp(header.value)[0]] ? props.item[splitProp(header.value)[0]].title : '-'"
                    width-item="65"
                  />
                </div>

                <div v-else-if="header.value === 'to.name' || header.value === 'from.name'">
                  <BaseTextTooltip
                    :text-item="props.item[splitProp(header.value)[0]] ? props.item[splitProp(header.value)[0]].name : '-'"
                    width-item="100"
                    min-width-item="100%"
                  />
                </div>

                <div v-else-if="header.value === 'to.tax-number' || header.value === 'from.tax-number'">
                  {{ props.item[splitProp(header.value)[0]].data[splitProp(header.value)[1]] || '-' | maskCPForCNPJ }}
                </div>

                <div v-else-if="header.value.includes('amount')">
                  {{ props.item[header.value] | formatCurrency }}
                </div>

                <div v-else>
                  {{ props.item[header.value] || '-' }}
                </div>
              </td>
              <td
                v-if="header.value === 'admin-config'"
                :key="index"
                class="text-right"
              >
                <BaseButton
                  type="text"
                  color="blueff"
                  class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 text-decoration-underline"
                  button-class="app-font-regular"
                  @click="openAssetDetail(props.item)"
                >
                  + detalhes
                </BaseButton>
              </td>
            </template>
          </tr>
        </template>
      </BaseDataTable>
    </BaseContainer>

    <v-dialog
      :value="isModalActive"
      persistent
      transition="app-transition-to-right"
      content-class="app-transition-to-right-additional-configs"
      @input="updateModalActive"
    >
      <BaseModal
        style="height: 100vh; flex-wrap: nowrap;"
        :show-indeterminate-progress="modalAssetDetail.showIndeterminateProgress"
        :top-slot-content-title="modalAssetDetail.topSlotContentTitle"
        :remove-button-close="modalAssetDetail.removeButtonClose"
        :middle-slot-content-text-highlight="modalAssetDetail.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalAssetDetail.middleSlotContentText"
        :middle-check-agree="modalAssetDetail.middleCheckAgree"
        :bottom-buttons-align="modalAssetDetail.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalAssetDetail.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalAssetDetail.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalAssetDetail.removeButtonCancel"
        :remove-button-confirm="modalAssetDetail.removeButtonConfirm"
        body-background-color="#F8F8F8"
        @on-cancel="onCancelForm"
        @on-confirm="() => null"
      >
        <div
          class="mt-6"
          style="max-width: 645px; margin: 0 auto;"
        >
          <BaseCard
            :has-main-container="true"
          >
            <v-tabs
              v-model="tabIndex"
              align-with-title
              class="tabs tab-details"
              @change="getDynamicFields(tabIndex)"
            >
              <v-tabs-slider />
              <v-tab
                class="tab"
              >
                <span class="tab--text">&nbsp;Ativo</span>
              </v-tab>
              <v-tab
                v-if="registerFields.data && registerFields.data.fields.length"
                class="tab"
              >
                <span class="tab--text">&nbsp;Registro</span>
              </v-tab>
            </v-tabs>
          </BaseCard>
          <v-tabs-items
            v-model="tabIndex"
            style="background-color: transparent;"
          >
            <v-tab-item :transition="false">
              <template v-if="tabIndex == 0">
                <CreateOrEditAsset
                  context-actions="edit"
                  :header-title="'Ativo'"
                  :header-title-description="'Veja ou edite dados do ativo'"
                  :asset-object="assetDetail"
                  :clear-form="!isModalActive"
                  scope-schema="asset"
                  :is-admin-editing="isAdminEditing"
                  @updateAssetList="getAssetMovement"
                />
              </template>
              <BaseCard
                :base-card-container-classes="'mt-4'"
                :has-main-container="true"
                :is-header-title-description="true"
              >
                <template v-slot:header-title>
                  <div class="pl-4">
                    Movimentos
                  </div>
                </template>
                <template v-slot:header-title-description>
                  <div class="pl-4 mb-4">
                    Veja movimentos contábeis relacionados ao ativo
                  </div>
                </template>
                <AssetPostingList
                  :selected-movement="assetPostings"
                  :movement-category="movementCategory"
                />
              </BaseCard>
            </v-tab-item>
            <v-tab-item
              v-if="registerFields.data && registerFields.data.fields.length"
              :transition="false"
            >
              <template v-if="tabIndex == 1">
                <CreateOrEditAsset
                  context-actions="read"
                  :header-title="'Dados do registro'"
                  :header-title-description="'Veja logs, parcelas e dados de registro do ativo'"
                  :asset-object="assetDetail"
                  :clear-form="!isModalActive"
                  scope-schema="register"
                  :is-admin-editing="isAdminEditing"
                  :register-asset="true"
                  @updateAssetList="getAssetMovement"
                />
                <BaseCard
                  id="installmentsAndLogs"
                  :base-card-container-classes="'mt-4'"
                  :has-main-container="true"
                  :is-header-title-description="true"
                >
                  <v-tabs
                    v-model="tabRegister"
                    align-with-title
                    class="tabs tab-details"
                    style="background-color: transparent;"
                  >
                    <v-tabs-slider />
                    <v-tab
                      disabled
                      class="tab"
                    >
                      <span class="tab--text">&nbsp;Parcelas</span>
                    </v-tab>
                    <v-tab
                      disabled
                      class="tab"
                    >
                      <span class="tab--text">&nbsp;Logs</span>
                    </v-tab>
                  </v-tabs>
                </BaseCard>
                <v-tabs-items
                  v-model="tabRegister"
                  style="background-color: transparent;"
                >
                  <v-tab-item>
                    <AssetsDetailsRegister
                      :header-title="'Parcelas do ativo'"
                      :header-title-description="'Veja as parcelas relacionadas ao ativo'"
                      context-registration="installments"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <AssetsDetailsRegister
                      :header-title="'Logs do ativo'"
                      :header-title-description="'Veja as entradas de log relacionadas ao ativo'"
                      context-registration="logs"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </BaseModal>
    </v-dialog>

    <AssetsCollection
      :show-assets-collection="showAssetsCollection"
      :is-admin-editing="openSideAssetsCollection"
      @hideAssetsCollection="closeAssetsCollection"
      @updateAssetList="updateAssetList"
      @deleteDraftMovementChild="deleteDraftMovementChild"
      @showError="showError"
    />

    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import loader from '../../global/loader'
import BaseDataTable from '../BaseDataTable.vue'
import BaseModal from '../BaseModal'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../BaseSnackbar'
import ProgramRoles from "@/mixins/ProgramRoles";
import AssetsCollection from './AssetsCollection'
import AssetPostingList from '../Program/AssetPostingList'
import MovementsExport from '../../mixins/MovementsExport'
import BaseFilters from '../BaseFilters'
import Warning from '../Program/Warning.vue'
import CreateOrEditAsset from '../Program/Files/CreateOrEditAsset'
import BaseTextTooltip from '../BaseTextTooltip'
import BaseAlert from '../BaseAlert'
import AssetsDetailsRegister from './AssetsDetailsRegister'

export default {
  components: {
    BaseDataTable,
    BaseModal,
    AssetsCollection,
    AssetPostingList,
    BaseFilters,
    Warning,
    CreateOrEditAsset,
    BaseTextTooltip,
    BaseSnackbar,
    BaseAlert,
    AssetsDetailsRegister
  },
  mixins: [ProgramRoles, BaseSnackbarConfiguration, MovementsExport],
  props: {
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    headerTitle: {
      type: String,
      required: false,
      default: 'Ativos'
    },
    headerTitleDescription: {
      type: String,
      required: false,
      default: 'Lista de ativos disponíveis para negociar.'
    },
    openSideAssetsCollection: {
      type: Boolean,
      required: false,
      default: false
    },
    openModalAssetDetail: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    movementCategory: "assets",
    showAssetsCollection: false,
    showWarningMessage: false,
    availableAmount: 0,
    tabIndex: null,
    tabRegister: null,
    registerFields: {},
    scopeSchema: 'asset-table',
    fieldsAssetColumn: [],
    tableAssetHeaders: [],
    selected: [],
    assetPostings: {},
    filterData: {
      filters: [],
      pagination: {
        sort_by: "number.keyword",
        sort_order: "desc"
      }
    },
    filter: {
      category: "assets",
      start_date: '',
      end_date: '',
      page: 0,
      size: 0,
    },
    pagination: {
      page: 1,
      itemsPerPage: 25
    },
    isModalActive: false,
    assetDetail: {
      valid: false,
      noFocus: true,
      buyer: '',
      number: '',
      "post-date": '',
      amount: '',
      status: '',
      origin: '',
      type: {
        title: ''
      },
      selectField: ''
    },
    modalAssetDetail: {
      showIndeterminateProgress: false,
      topSlotContentTitle: "Detalhes do ativo",
      removeButtonClose: false,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: '',
      bottomButtonsAlign: "right",
      bottomSlotContentButtonCancelLabel: "Cancelar",
      bottomSlotContentButtonConfirmLabel: "Salvar",
      removeButtonCancel: true,
      removeButtonConfirm: true,
      onCancel: null,
      onConfirm: null
    },
    filterAlert: 'Edite os filtros acima para mudar sua busca de ativos.'
  }),
  computed: {
    ...mapState({
      assetList: (state) => {
        return state.programMovements.programMovements
      },
      programMembers: state => state.programMembers.programMembers,
      programDraftMovementId: state => state.programDraftMovements.programDraftMovementId,
      loadPage: (state) => state.loadPage,
      programDraftMovementsChildren: state => state.programDraftMovements.programDraftMovementsChildren,
      programInitiator: state => state.platformProgram.programInitiator,
      hideAllData: state => state.platformProgram.hideAllData
    }),
    disableExportData(){
      if(this.filterData.filters.length){
        let dates = this.filterData.filters.find(el => el.key == 'date' || el.key == 'created_at' || el.key == 'post-date')
        let values = dates ? dates.values : []
        return values.length == 0 || this.getDiffDays(values) > 90 ? true : false
      }
      return true
    },
    dataAssetList() {
      return this.assetList.content ? this.assetList.content.map(element => {
        const {type, to, from, data} = element
        const asset = {
          type,
          to,
          from,
          ...data
        }

        return {
          ...asset,
          "post-date": data['post-date'] ? data['post-date'] : data.date,
          new_date: data.new_date ? data.new_date : data.date,
          disabled: data.status.category !== "created" || data['post-date'] < this.currentDate()
        }
      }) : []
    },
    assetListTotalElements() {
      return this.assetList.page ? this.assetList.page.total_elements : 0
    }
  },
  methods: {
    ...mapActions('programMovements',
      ['apiGetSchemas', 'apiPatchProgramMovements']),
    ...mapActions('programDraftMovements',
      ['apiGetDraftMovements', 'apiCreateDraftMovement', 'apiSendDraftMovementChildren',
        'apiGetDraftMovementsChildren', 'apiDeleteDraftMovement', 'apiDeleteDraftMovementChild']),
    ...mapActions('programFilters', ['apiPostMovementsFilters']),
    ...mapActions('programMembers', ['apiGetProgramMembers']),
    ...mapMutations(["setDisableNavigation"]),
    ...mapMutations('programDraftMovements', ['setDraftMovementsChildren', 'setDraftMovementsId']),
    ...mapMutations('programMovements', ['setProgramMovements']),
    updateModalActive(value) {
      this.isModalActive = value
    },
    onCancelForm() {
      this.isModalActive = false
    },
    splitProp(key) {
      let splittedKey = key.split('.')
      return splittedKey
    },
    isSortable(header){
      return header.sortable
    },
    showError(value){
      this.showFeedback({
        message: value,
        color: 'error'
      })
    },
    async getDynamicFields(tabIndex) {
      this.registerFields = await this.apiGetSchemas({ scope: 'register' })
      if (tabIndex === 0) {
        await this.apiGetSchemas({ scope: 'asset' })
      } else if (tabIndex === 1) {
        await this.apiGetSchemas({ scope: 'register' })
      }
    },
    openAssetDetail(asset) {
      //deepClone do objeto, pois a referencia dos nested continuavam
      //dessa maneira ele cria uma nova referencia para os nested
      const currentAsset = this.assetList.content.filter(a => a.data.id == asset.id)[0]
      const mergedAsset = {...currentAsset,...currentAsset['data']}

      if (!mergedAsset.new_date) {
        mergedAsset.new_date = mergedAsset.date
      }
      this.assetDetail = JSON.parse(JSON.stringify(mergedAsset))
      if (this.$refs.amount) {
        this.$refs.amount.$el.getElementsByTagName('input')[0].value = this.assetDetail.amount
      }
      this.isModalActive = true
      this.assetPostings = asset
    },
    preventClickOnCheckbox(asset) {
      return asset.item.disabled || asset.isSelected || this.loadPage ? null : "click";
    },
    dataAssetListForAPI(selectedAsset) {
      return selectedAsset.map(element => {
        const {statusColor, status, buyer, disabled, type, to, from, ...data} = element
        return {id: "asset", type: type, to, from, data: data}
      });
    },
    async selectAssetToDraftMovement(selectedAsset) {
      if (this.programDraftMovementId) {
        await this.sendChildrenToApi(this.programDraftMovementId,
          this.dataAssetListForAPI(selectedAsset))
      } else {
        const result = await this.apiCreateDraftMovement(
          {type: 'trade', data: {"action": "start", "data": {}}})
        const idMovementTrade = result.headers.location.split('/').reverse()[0]
        await this.sendChildrenToApi(idMovementTrade, this.dataAssetListForAPI(selectedAsset))
      }
    },
    async sendChildrenToApi(id, asset) {
      try {
        await this.apiSendDraftMovementChildren({type: 'trade', id: id, data: asset})
      } catch(err){
        this.showFeedback({
          message: err.response.data.message ? err.response.data.message : 'Erro ao adicionar ativo.',
          color: 'error'
        })
      }
      await this.getDraftMovements()
      this.selectAssetAndAddScore()
      this.openAssetsCollection()
    },
    convertStatus(value) {
      const status = {
        'in-progress': "progress",
        done: "running",
        created: "ok",
        error: "error"
      }
      return status[value]
    },
    async toggleAll(someItems) {
      if (someItems) {
        await this.apiDeleteDraftMovement({type: 'trade'})
        this.setDraftMovementsId(null)
        this.setDraftMovementsChildren([])
        this.closeAssetsCollection()
      } else {
        this.selected = this.dataAssetList.filter(elem => !elem.disabled)
        if (this.selected.length > 0) {
          this.selectAssetToDraftMovement(this.selected)
        }
      }
    },
    addOrDeleteAssetDraftMovement(selected, item) {
      loader.startProgressLoadPage()
      if (selected) {
        this.deleteDraftMovementChild(item.score)
      } else {
        this.selectAssetToDraftMovement([item])
      }
    },
    async deleteDraftMovementChild(id) {
      await this.apiDeleteDraftMovementChild(
        {type: 'trade', idDraft: this.programDraftMovementId, idChild: id})
      await this.getDraftMovements()
      this.selectAssetAndAddScore()
      if(this.programDraftMovementsChildren.length == 0) this.setDraftMovementsId(null)
    },
    selectAssetAndAddScore() {
      this.selected = this.dataAssetList.filter(asset =>
        this.programDraftMovementsChildren.find(child => {
          if (child.data.id === asset.id) {
            asset.score = child.score
            return asset
          }
        })
      )
    },
    updateAssetList() {
      setTimeout(() => {
        this.getAssetMovement()
        this.selected = [];
      }, 4000)
    },
    openAssetsCollection() {
      this.showAssetsCollection = true
      this.setDisableNavigation(true)
    },
    closeAssetsCollection() {
      this.showAssetsCollection = false
      this.setDisableNavigation(false)
      this.getDraftMovements()
      this.getAssetMovement()
    },
    async changeSort(column) {
      if (this.filterData.pagination.sort_by === column) {
        this.filterData.pagination.sort_order = this.filterData.pagination.sort_order == 'desc'
          ? 'asc' : 'desc'
      } else {
        this.filterData.pagination.sort_by = column
        this.filterData.pagination.sort_order = 'desc'
      }

      this.filterData.pagination = {
        ...this.filterData.pagination,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage
      }

      let result = await this.apiPostMovementsFilters(this.filterData)
      this.setProgramMovements(result.data)
    },
    async getAssetMovement() {
      this.setPaginationToFilter()
      loader.startProgressLoadPage()

      let result = await this.apiPostMovementsFilters(this.filterData)
      this.setProgramMovements(result.data)

      this.selectAssetAndAddScore()
      loader.stopProgressLoadPage()
    },
    updatePagination(pagination) {
      const reducePaginationAttributes = {
        page: pagination.page,
        itemsPerPage: pagination.itemsPerPage,
      }
      this.pagination = reducePaginationAttributes
      Object.assign(this.filterData.pagination, {page:pagination.page, size: pagination.itemsPerPage})
      this.getAssetMovement()
    },
    updateModel(event) {
      this.selected = event;
    },
    toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    },
    minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - days))
    },
    currentDate(){
      return new Date().toISOString().substr(0, 10)
    },
    setPaginationToFilter() {
      this.filterData.pagination = {
        ...this.filterData.pagination,
        page: this.pagination.page,
        size: this.pagination.itemsPerPage
      }
    },
    async getDraftMovements() {
      await this.apiGetDraftMovements('trade')

      if (this.programDraftMovementId) {
        await this.apiGetDraftMovementsChildren({type: 'trade', id: this.programDraftMovementId})
      }
    },
    filterAssets(filters) {
      this.pagination.page = 1

      const newPagination = this.filterData.pagination.sort_by ? this.filterData.pagination : { page: this.pagination.page, size: this.pagination.itemsPerPage }

      Object.assign(this.filterData, {
        ...filters,
        pagination: newPagination
      })

      this.getAssetMovement()
    },
    filtersToReport(filtersList) {
      let filters = {}
      filtersList.forEach(filter => {
        filters = { ...filters, [`filter.${filter.key}`] : filter.values.join()}
      })
      return filters
    },
    async executeExportData() {
      const filtersReport = {}
      if(this.filterData.filters.length > 0) {
        Object.assign(filtersReport, this.filtersToReport(this.filterData.filters))
      }else {
        let filterData = [{
          key: "date",
          values: [
          this.toLocalDate(this.minusDays(new Date(), '30')).toISOString().substr(0, 10),
          this.toLocalDate(new Date()).toISOString().substr(0, 10)
          ],
        }]
        Object.assign(filtersReport, this.filtersToReport(filterData))
      }

      const data = {
        action: 'asset-report',
        data: {
          ...filtersReport,
          'program-id': this.programInitiator,
          category: 'asset',
          'user-tax-number': this.getActiveOrganization() ? this.getActiveOrganization().tax_number : '',
        }
      }

      await this.apiPatchProgramMovements({category: 'assets', data})
      this.showWarningMessage = true
    },
    async tableAssetSchema(role = null){
      let result = null
      if(role){
        result = await this.apiGetSchemas({ scope: this.scopeSchema, role })
      } else {
        result = await this.apiGetSchemas({ scope: this.scopeSchema })
      }
      this.fieldsAssetColumn = result.data && result.data.fields || []
      this.parseHeadersAssetTable()
    },

    parseHeadersAssetTable(){
      let tableAssetColumn = this.fieldsAssetColumn.sort((a, b) => a.sequence - b.sequence) || []
      let fields = tableAssetColumn.map(header => {
        return {
          hidden: header.hidden,
          text: header.name,
          value: header.key,
          class: header.key,
          'input_type': header['input_type'],
          sortable: true
        }
      })
      fields.push({
        hidden: false,
        text: '',
        value: 'admin-config',
        class: '',
        sortable: false
      })
      this.tableAssetHeaders = [...fields]
    },
  },
  async mounted() {
    await this.tableAssetSchema()
    this.getDraftMovements()
    this.showAssetsCollection = this.openSideAssetsCollection
  },
  beforeRouteLeave(to, from, next){
    this.setProgramMovements([])
    next()
  }
}
</script>

<style lang="scss">

.filter-v-alert {
  padding: 5px 10px!important;
  .v-btn--icon.v-size--small .v-icon, .v-btn--fab.v-size--small .v-icon {
    height: 20px;
    font-size: 20px;
    width: 20px;
  }

}
.filter-v-alert.v-alert {
  font-size: 14px!important;
}
.filter-v-alert.v-alert--text:before {
  background: var(--v-bluefa-base)!important;
}

.asset-list .asset-list__notification {
 font-size: 16px;
  margin: -40px 0 18px -24px;
  width: calc(100% + 48px);
}

.asset-data-table {
  .v-input {
    padding-top: 4px;
  }

  .v-text-field__details {
    display: none;
  }
}

.asset-list__header__actions {
  display: flex;
  align-items: center;
}

.asset-list .base-data-table {
  .header-sortable {
    cursor: pointer;
  }

  .check {
    width: 40px;
  }

  .status {
    width: 177px;
  }

  .type {
    width: 65px;
  }

  .number {
    width: 140px;
  }

  .date {
    width: 40px;
  }

  .description {
    width: 500px;
  }

  .amount {
    width: 150px;
  }

  .post-date {
    width: 130px;
  }

  .seller, .buyer {
    min-width: 100px;
  }

}

.tabs .tab--text {
  font-weight: 700;
  font-size: 14px;
}

.tab-details.v-tabs > .v-tabs-bar {
  background-color: #F8F8F8!important;
}

.asset-list {
  .base-data-table th {
    font-weight: 600 !important;
  }

  .v-data-footer {
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
  }

  .asset-list__label-cart {
    border-radius: 50px 0 0 50px;
    font-size: 12px !important;
    cursor: pointer;
    min-width: 187px;
    height: 30px;
    padding: 3px 23px 3px 5px;
    margin: 12px -25px 0 0;
    box-shadow: 0px 2px 3px -1px rgb(0 0 0 / 25%);
  }

  .animate-label-cart {
    transition: all 5s ease;
    animation: animateLabelCart 1s infinite linear both;
  }

  @keyframes animateLabelCart {
    0% { min-width: 189px; }
    50% { min-width: 200px; }
    100% { min-width: 189px; }
  }

  .asset-list__item--status {
    color: var(--custom-theme-black-054) !important;
    display: inline-block;
    font-size: 10px !important;
    height: 24px !important;
    min-width: 120px;
    margin: 4px !important;
    text-align: center;
  }
}
</style>
