<template>
  <section
    v-if="hasContentPermission"
    class="base-container"
    :class="baseContainerClasses"
  >
    <div class="base-container__header pb-4">
      <div>
        <v-row
          v-if="isHeaderTitle"
          align="center"
        >
          <v-icon
            v-if="isHeaderIconTitle"
            class="mr-2"
          >
            <slot name="header-icon-title" />
          </v-icon>
          <h2 class="headline app-headline font-weight-bold">
            <slot name="header-title" />
          </h2>
        </v-row>
        <p
          v-if="isHeaderTitleDescription"
          class="body-1"
        >
          <slot name="header-title-description" />
        </p>
      </div>
      <v-spacer v-if="activeSpacer" />
      <div
        v-if="isHeaderActions"
        class="base-container__header__actions text-right"
      >
        <slot name="header-actions" />
      </div>
    </div>
    <div class="base-container__main app-headline">
      <slot />
    </div>
  </section>
  <section
    v-else
    class="d-flex justify-center"
    style="height: calc(100vh - 180px)"
  >
    <div class="text-center align-self-center">
      <h1>Ops!</h1>
      <p>Você não tem permissão para acessar esta página, verifique seu perfil de acesso.</p>
      <BaseButton
        color="primary"
        class="mx-0 my-4"
        @click="$router.push('/platform-home')"
      >
        Voltar ao início
      </BaseButton>
    </div>
  </section>
</template>

<script>
import ProgramRoles from '../mixins/ProgramRoles'
import permissions from '../mixins/permissions'

export default {
  name: "BaseContainer",
  mixins: [ProgramRoles, permissions],
  props: {
    activeSpacer: {
      type: Boolean,
      default: false
    },
    baseContainerClasses: {
      type: String,
      default: ""
    },
    permissionList: {
      type: Array,
      required: false,
      default: ()=>[]
    }
  },
  computed: {
    isHeaderTitleDescription(){
      return !!this.$slots['header-title-description']
    },
    isHeaderTitle(){
      return !!this.$slots['header-title']
    },
    isHeaderActions(){
      return !!this.$slots['header-actions']
    },
    isHeaderIconTitle(){
      return !!this.$slots['header-icon-title']
    },
    hasContentPermission(){
      if(this.permissionList.length > 0) {
        if(this.userHasProgramRoles(this.permissionList) || this.userHasResourceRole(this.permissionList) || this.userHasGroup(this.permissionList)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
  .base-container__header, .base-container__header__actions {
    display: flex;
    align-items: center;
  }
</style>
