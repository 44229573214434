<template>
  <div class="platform-trades">
    <Warning
      v-if="showWarningMessage"
      icon="notifications"
      type="info"
      classes="trade-list__notification"
    >
      O relatório está sendo gerado e em breve estará disponível na página de Relatórios,
      <router-link
        to="/files/reports"
      >
        clique aqui para ir
      </router-link>.
    </Warning>

    <BaseContainer
      :active-spacer="true"
      :permission-list="['seller', 'buyer', 'trading-partner', 'owner']"
    >
      <template v-slot:header-title>
        {{ headerTitle }}
      </template>
      <template v-slot:header-title-description>
        {{ headerTitleDescription }}
      </template>
      <v-navigation-drawer
        v-model="activeOfferDrawer"
        app
        class="app-navigation"
        color="#00000008"
        clipped
        floating
        ligth
        fixed
        mobile-breakpoint="990"
        disable-resize-watcher
        right
        width="428"
      >
        <template v-if="!offer.hasSendSuccess">
          <v-toolbar
            class="mb-6 elevation-0"
            color="transparent"
          >
            <BaseButton
              :has-icon="true"
              @click="closeMovementDetail"
            >
              <v-icon>close</v-icon>
            </BaseButton>
          </v-toolbar>
          <MovementDetails
            class="ma-4"
            :actions-buttons="movement.actionsButtons"
            :section="getMovementSections"
            :title="movement.title"
            @click="handleClickMovementDetails"
          >
            <template v-slot:trade>
              <BaseResumeInfos
                class="my-4"
                :resume-infos="tradeOfferAssets"
              />
            </template>

            <template v-slot:data-payment>
              <Warning
                v-if="!hasBankAccount"
                class="mt-2"
                icon="warning"
                type="warning"
                :width="360"
                theme="light"
              >
                <p>
                  <strong>Conta bancária:</strong> Cadastre uma conta
                  bancária no perfil da sua organização antes de realizar uma negociação.
                  <a
                    class="app-font-semibold text-decoration-underline"
                    :href="createLinkToBankAccount"
                  >
                    Cadastrar conta.
                  </a>
                </p>
              </Warning>
              <BaseDataBank
                v-else
                description=""
                :bank-value="tradeOfferBankAccount.bankValue"
                :agency-value="tradeOfferBankAccount.agencyValue"
                :account-value="tradeOfferBankAccount.accountValue"
                style="padding: 8px 0 !important;"
              />
            </template>

            <template v-slot:offer>
              <FormCreateOffer
                :clear-form="clearOfferForm"
                scope-schema="offer"
                :offer-object="offer"
                :active="activeOfferDrawer"
                @changeClearOfferForm="changeClearOfferForm"
                @changeOfferObject="changeOfferObject"
                @changeHandleInputForm="changeHandleInputForm"
              />
            </template>
          </MovementDetails>
        </template>
        <template v-else>
          <BaseTemplateSuccess
            background-color="transparent"
            action-text="FECHAR"
            message-text="Acesse o menu negociações para acompanhar <br /> tudo o que você enviou."
            message-title="Proposta enviada!"
            @click="closeMovementDetail"
          >
            <template v-slot:header>
              <BaseButton
                :has-icon="true"
                @click="closeMovementDetail"
              >
                <v-icon>close</v-icon>
              </BaseButton>
            </template>
          </BaseTemplateSuccess>
        </template>
      </v-navigation-drawer>
      <div class="platform-trades__header__actions">
        <BaseFilters
          scope="trades"
          filter-scope="trade-assets"
          :pagination="pagination"
          :load-page="loadPage"
          :class="{ 'app-apply-blur' : isAdminEditing && openModalOffer}"
          :is-admin-editing="!openModalOffer && isAdminEditing"
          @executeActionFilter="filterTrades"
        />
        <v-spacer />

        <v-tooltip
          bottom
          :disabled="!disableExportData"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 text-decoration-underline text-uppercase"
                :class="{ 'app-apply-blur' : isAdminEditing }"
                button-class="app-font-regular"
                :is-disabled="disableExportData"
                @click="executeExportData"
              >
                Gerar Relatório
              </BaseButton>
            </span>
          </template>
          Selecione um intervalo de até 90 dias
        </v-tooltip>
      </div>
      <BaseAlert
        :class="{ 'app-apply-blur' : isAdminEditing }"
      >
        <b>Filtro aplicado:</b> {{ filterAlert }}
      </BaseAlert>
      <CardDataTable
        background-color="transparent"
        :class="{ 'app-apply-blur' : isAdminEditing }"
        :hide-headers="true"
        :headers="headers"
        :items="orderTrades"
        :options="pagination"
        :total-items="tradeListTotalElements"
        :is-admin-editing="isAdminEditing"
        @click="handleClickTrade"
        @item-expanded="tradeExpanded"
        @update-pagination="updatePagination"
      >
        <template v-slot:inject-header="{headers}">
          <th
            v-for="(header, index) in headers.headers"
            :key="index"
            style="white-space: nowrap;"
            :hidden="header.permissions ? !userHasProgramRoles(header.permissions) : false"
            :class="[filterData.pagination.sort_order, header.value === filterData.pagination.sort_by ? 'active' : '', header.class, isSortable(header) ? 'header-sortable' : '']"
            @click="isSortable(header) ? changeSort(header.value) : null"
          >
            {{ translateRoleType(header.text) }}
            <v-icon
              v-if="isSortable(header)"
              class="v-data-table-header__icon"
              small
            >
              arrow_upward
            </v-icon>
          </th>
        </template>
        <template v-slot:inject-expand="scoped">
          <v-progress-linear
            v-if="loadPage && (currentTradeIdExpanded === scoped.items.items.item.id)"
            indeterminate
            color="primary"
            height="1"
          />
          <v-divider v-else />
          <template v-if="getActionTradeList(scoped.items.items.item.status.category).length > 0">
            <v-divider />
            <BaseActionGroup
              description="Ações para essa negociação"
              :action-list="getActionTradeList(scoped.items.items.item.status.category)"
              :current-object="scoped.items.items.item"
              @onClickAction="onClickTradeAction"
            />
          </template>
          <v-divider v-if="groupPaymentsAccountsOfTrades[scoped.items.items.item.id].item.length > 0" />
          <div class="platform-trades__payments d-flex justify-end flex-wrap">
            <BaseDataBank
              v-for="payment in groupPaymentsAccountsOfTrades[scoped.items.items.item.id].item"
              :key="payment.data.id"
              :description="payment.type ? payment.type.title : ''"
              style="width: 396px; padding: 8px 24px !important;"
              :agency-value="payment.data.bank_account.agency"
              :account-value="payment.data.bank_account.account"
              :bank-value="payment.data.bank_account.bank"
            />
          </div>
          <v-divider />
          <v-tabs
            v-model="scoped.items.items.item.tab"
            align-with-title
            class="tabs"
          >
            <v-tabs-slider color="primary" />
            <v-tab
              class="tab"
            >
              <span
                v-if="!isTradingPartner()"
                class="tab--text"
              >
                {{ (groupAssetsChildrenOfTrades[scoped.items.items.item.id].item).length }}
              </span>
              <span class="tab--text">&nbsp;ATIVO(S)</span>
            </v-tab>
            <v-tab
              class="tab"
            >
              <span
                v-if="!isTradingPartner()"
                class="tab--text"
              >
                {{ filterOffers(orderGroupOffersChildrenOfTrades(groupOffersChildrenOfTrades[scoped.items.items.item.id].item)).length }}
              </span>
              <span class="tab--text">&nbsp;PROPOSTA(S)</span>
            </v-tab>
          </v-tabs>
          <v-divider />
          <v-tabs-items
            v-model="scoped.items.items.item.tab"
            style="background-color: transparent;"
          >
            <v-tab-item>
              <BaseDataTable
                background-color="#F4F5F8"
                :headers="tableAssetHeaders"
                class="trade-table-dinamic"
                :items="orderGroupAssetsChildrenOfTrades(groupAssetsChildrenOfTrades[scoped.items.items.item.id].item)"
                :options="groupAssetsChildrenOfTrades[scoped.items.items.item.id].filter.getPaginationsOptionsToBaseDataTable()"
              >
                <template v-slot:inject-items="scopedItems">
                  <tr
                    v-for="props in scopedItems"
                    :key="props.index"
                  >
                    <template
                      v-for="header, index in tableAssetHeaders"
                    >
                      <td
                        v-if="header.value !== 'admin-config'"
                        :key="index"
                        class="text-left"
                      >
                        <div
                          v-if="header.hidden === 'true' && hideAllData"
                        >
                          {{ hideData(props.item[header.value], header.hidden) }}
                        </div>
                        <div v-else-if="header.value === 'status'">
                          {{ props.item[header.value].name }}
                        </div>

                        <div v-else-if="header.datasource">
                          {{ executeDatasourceCode(props.item, scoped.items.items.item, header.datasource) }}
                        </div>

                        <div v-else-if="header.value.includes('date') || header.value === 'created_at'">
                          {{ props.item[header.value] | getDate }}
                        </div>

                        <div v-else-if="header.value === 'type.title'">
                          <BaseTextTooltip
                            :text-item="props.item[splitProp(header.value)[0]] ? props.item[[splitProp(header.value)[0]]].title : '-'"
                            width-item="65"
                          />
                        </div>

                        <div v-else-if="header.value === 'to.name' || header.value === 'from.name'">
                          <BaseTextTooltip
                            :text-item="props.item[splitProp(header.value)[0]][splitProp(header.value)[1]] || '-'"
                            width-item="100"
                            min-width-item="100%"
                          />
                        </div>

                        <div v-else-if="header.value === 'to.tax-number' || header.value === 'from.tax-number'">
                          {{ props.item[splitProp(header.value)[0]].data[splitProp(header.value)[1]] || '-' | maskCPForCNPJ }}
                        </div>

                        <div v-else-if="header.value.includes('amount') || header.value.includes('emolument')">
                          {{ props.item[header.value] || '0' | formatCurrency }}
                        </div>

                        <div v-else>
                          {{ props.item[header.value] || '-' }}
                        </div>
                      </td>
                      <td
                        v-if="header.value === 'admin-config'"
                        :key="index"
                        class="text-right"
                      >
                        <BaseButton
                          type="text"
                          color="blueff"
                          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 text-decoration-underline"
                          button-class="app-font-regular"
                          @click="openAssetDetail(props.item, scoped.items.items.item.id)"
                        >
                          + detalhes
                        </BaseButton>
                      </td>
                    </template>
                  </tr>
                </template>
                <template v-slot:no-data-text-message>
                  <h4>Nenhum Ativo</h4>
                </template>
              </BaseDataTable>
            </v-tab-item>

            <v-tab-item>
              <BaseDataTable
                background-color="#F4F5F8"
                :headers="tableOfferHeaders"
                class="trade-table-dinamic"
                :items="filterOffers(orderGroupOffersChildrenOfTrades(groupOffersChildrenOfTrades[scoped.items.items.item.id].item))"
                :options="groupOffersChildrenOfTrades[scoped.items.items.item.id].filter.getPaginationsOptionsToBaseDataTable()"
              >
                <template v-slot:no-data-text-message>
                  <h4>Nenhuma Proposta</h4>
                  <p>Não existem propostas criadas para esta negociação.</p>
                </template>

                <template
                  v-slot:inject-items="scopedItems"
                >
                  <tr
                    v-for="props in scopedItems"
                    :key="props.index"
                    :class="{'font-weight-bold' : props.item.status.category === 'done'}"
                  >
                    <template
                      v-for="header, index in tableOfferHeaders"
                    >
                      <td
                        v-if="header.value !== 'admin-config'"
                        :key="index"
                        class="text-left"
                      >
                        <div
                          v-if="header.hidden === 'true' && hideAllData"
                        >
                          {{ hideData(props.item[header.value], header.hidden) }}
                        </div>
                        <div v-else-if="header.value === 'status'">
                          {{ props.item[header.value].name }}
                        </div>

                        <div v-else-if="header.value.includes('date') || header.value === 'created_at'">
                          {{ props.item[header.value] | getDate }}
                        </div>

                        <div v-else-if="header.value === 'type'">
                          <BaseTextTooltip
                            :text-item="props.item[header.value] ? props.item[header.value].title : '-'"
                            width-item="65"
                          />
                        </div>

                        <div v-else-if="header.value === 'to.name' || header.value === 'from.name'">
                          <BaseTextTooltip
                            :text-item="props.item[splitProp(header.value)[0]][splitProp(header.value)[1]] || '-'"
                            width-item="100"
                            min-width-item="100%"
                          />
                        </div>

                        <div v-else-if="header.value.includes('amount') || header.value.includes('emolument')">
                          {{ props.item[header.value] || '0' | formatCurrency }}
                        </div>
                        <div v-else-if="header.value.includes('rate') && header.value !== 'rate_type'">
                          {{ parseTax(props.item[header.value] || '0') }}
                        </div>

                        <div v-else-if="header.value ==='rate_type'">
                          {{ convertRate(props.item[header.value]) }}
                        </div>

                        <div v-else>
                          {{ props.item[header.value] || '-' }}
                        </div>
                      </td>
                      <td
                        v-if="header.value === 'admin-config'"
                        :key="index"
                        class="text-right"
                      >
                        <BaseButton
                          v-if="isSeller() && !disableOfferActions(groupOffersChildrenOfTrades[scoped.items.items.item.id].item, props.item)"
                          type="text"
                          color="blueff"
                          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                          :is-disabled="approveOrReproveModal.active"
                          @click="() => handleClickApproveOrReproveOffer('reprove', props.item, scoped.items.items.item.id)"
                        >
                          Rejeitar
                        </BaseButton>
                        <BaseButton
                          v-if="isSeller() && !disableOfferActions(groupOffersChildrenOfTrades[scoped.items.items.item.id].item, props.item)"
                          type="text"
                          color="blueff"
                          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                          :is-disabled="approveOrReproveModal.active"
                          @click="() => handleClickApproveOrReproveOffer('approve', props.item, scoped.items.items.item.id)"
                        >
                          Aceitar
                        </BaseButton>
                        <BaseButton
                          v-if="isTradingPartner() && props.item.status.category == 'on-hold'"
                          type="text"
                          color="blueff"
                          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                          :is-disabled="activeOfferDrawer"
                          @click="() => handleClickApproveOrReproveOffer('update', props.item, scoped.items.items.item.id)"
                        >
                          Editar
                        </BaseButton>
                        <BaseButton
                          v-if="isTradingPartner()"
                          type="text"
                          color="blueff"
                          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                          :is-disabled="disableCancelOffer(props.item.status.category)"
                          @click="() => handleClickApproveOrReproveOffer('cancel', props.item, scoped.items.items.item.id)"
                        >
                          Cancelar
                        </BaseButton>
                      </td>
                    </template>
                  </tr>
                </template>
              </BaseDataTable>
            </v-tab-item>
          </v-tabs-items>
          <template v-if="scoped.items.items.item.cancel_reason">
            <v-divider class="mt-3" />
            <v-alert
              color="red"
              border="left"
              colored-border
              type="info"
              dense
            >
              <b>Motivo:</b> {{ scoped.items.items.item.cancel_reason }}
            </v-alert>
          </template>
        </template>
      </CardDataTable>
      <BaseSnackbar
        :active-snackbar="feedback"
        :type-snackbar="feedbackColor"
        :text-snackbar="feedbackMessage"
        @closeSnackbar="feedback = !feedback"
      />
      <v-dialog
        v-model="tradeActionModal.active"
        max-width="560"
        persistent
      >
        <BaseModal
          :show-indeterminate-progress="tradeActionModal.showIndeterminateProgress"
          :top-slot-content-title="tradeActionModal.topSlotContentTitle"
          :remove-button-close="tradeActionModal.removeButtonClose"
          :middle-slot-content-text-highlight="tradeActionModal.middleSlotContentTextHighlight"
          :middle-slot-content-text="tradeActionModal.middleSlotContentText"
          :bottom-buttons-align="tradeActionModal.bottomButtonsAlign"
          :bottom-slot-content-button-cancel-label="tradeActionModal.bottomSlotContentButtonCancelLabel"
          :bottom-slot-content-button-confirm-label="tradeActionModal.bottomSlotContentButtonConfirmLabel"
          :remove-button-cancel="tradeActionModal.removeButtonCancel"
          :remove-button-confirm="tradeActionModal.removeButtonConfirm"
          :custom-enable-bottom-button-confirm-label="tradeActionModal.customEnableBottomButtonConfirmLabel"
          @on-cancel="() => tradeActionModal.onCancel()"
          @on-confirm="() => tradeActionModal.onConfirm()"
        >
          <template
            v-if="tradeActionModal.action === 'cancel'"
            v-slot:inject-form
          >
            <BaseInput
              v-model="tradeActionModal.reason"
              class="mt-4"
              label="Motivo"
              :rules="[isValid]"
              @update:error="(value) => { tradeActionModal.customEnableBottomButtonConfirmLabel = !value }"
            />
          </template>
        </BaseModal>
      </v-dialog>
      <v-dialog
        v-model="approveOrReproveModal.active"
        max-width="560"
        persistent
      >
        <BaseModal
          :show-indeterminate-progress="approveOrReproveModal.showIndeterminateProgress"
          :top-slot-content-title="approveOrReproveModal.topSlotContentTitle"
          :remove-button-close="approveOrReproveModal.removeButtonClose"
          :middle-image="approveOrReproveModal.middleImage"
          :middle-slot-content-text-highlight="approveOrReproveModal.middleSlotContentTextHighlight"
          :middle-slot-content-text="approveOrReproveModal.middleSlotContentText"
          :middle-check-agree="approveOrReproveModal.middleCheckAgree"
          :bottom-buttons-align="approveOrReproveModal.bottomButtonsAlign"
          :bottom-slot-content-button-cancel-label="approveOrReproveModal.bottomSlotContentButtonCancelLabel"
          :bottom-slot-content-button-confirm-label="approveOrReproveModal.bottomSlotContentButtonConfirmLabel"
          :remove-button-cancel="approveOrReproveModal.removeButtonCancel"
          :remove-button-confirm="approveOrReproveModal.removeButtonConfirm"
          :custom-enable-bottom-button-confirm-label="approveOrReproveModal.customEnableBottomButtonConfirmLabel"
          @on-cancel="closeApproveOrReproveOffer"
          @on-confirm="() => createApproveOrReproveOffer(selectedOfferModal)"
        >
          <template
            v-if="approveOrReproveModal.action === 'reprove'"
            v-slot:inject-form
          >
            <BaseInput
              v-model="approveOrReproveModal.reason"
              label="Motivo da recusa"
              :rules="[isValid]"
              @update:error="handleInputReasonIsError"
            />

            <p class="mt-3">
              Ao prosseguir, a ação não poderá ser desfeita.<br> Deseja recusar esta
              proposta?
            </p>
          </template>
        </BaseModal>
      </v-dialog>
      <v-dialog
        :value="isAssetModalActive"
        persistent
        transition="app-transition-to-right"
        content-class="app-transition-to-right-additional-configs"
        @input="updateModalActive"
      >
        <BaseModal
          style="height: 100vh; flex-wrap: nowrap;"
          :show-indeterminate-progress="modalAssetDetail.showIndeterminateProgress"
          :top-slot-content-title="modalAssetDetail.topSlotContentTitle"
          :remove-button-close="modalAssetDetail.removeButtonClose"
          :middle-slot-content-text-highlight="modalAssetDetail.middleSlotContentTextHighlight"
          :middle-slot-content-text="modalAssetDetail.middleSlotContentText"
          :middle-check-agree="modalAssetDetail.middleCheckAgree"
          :bottom-buttons-align="modalAssetDetail.bottomButtonsAlign"
          :bottom-slot-content-button-cancel-label="modalAssetDetail.bottomSlotContentButtonCancelLabel"
          :bottom-slot-content-button-confirm-label="modalAssetDetail.bottomSlotContentButtonConfirmLabel"
          :remove-button-cancel="modalAssetDetail.removeButtonCancel"
          :remove-button-confirm="modalAssetDetail.removeButtonConfirm"
          body-background-color="#F8F8F8"
          @on-cancel="onCancelForm"
          @on-confirm="() => null"
        >
          <div
            class="mt-6"
            style="max-width: 645px; margin: 0 auto;"
          >
            <CreateOrEditAsset
              scope-schema="trade-assets"
              :header-title="'Ativo'"
              :header-title-description="'Veja ou edite dados do ativo'"
              context-actions="edit"
              :asset-object="assetDetail"
            />
            <BaseCard
              :base-card-container-classes="'mt-4'"
              :has-main-container="true"
              :is-header-title-description="true"
            >
              <template v-slot:header-title>
                <div>
                  Movimentos
                </div>
              </template>
              <template v-slot:header-title-description>
                <div class="mb-4">
                  Veja movimentos contábeis relacionados ao ativo
                </div>
              </template>
              <AssetPostingList
                :selected-movement="assetPostings"
                :movement-category="movementCategory"
              />
            </BaseCard>
          </div>
        </BaseModal>
      </v-dialog>
    </BaseContainer>
  </div>
</template>

<script>

import CardDataTable from './CardDataTable'
import BaseDataBank from './BaseDataBank'
import BaseDataTable from './BaseDataTable'
import MovementDetails from './MovementDetails'
import BaseResumeInfos from './BaseResumeInfos'
import BaseTemplateSuccess from './BaseTemplateSuccess'
import BaseSnackbar from './BaseSnackbar'
import BaseModal from './BaseModal'
import Warning from './Program/Warning.vue'
import MovementsExport from '../mixins/MovementsExport'
import BaseFilters from './BaseFilters'
import AssetPostingList from './Program/AssetPostingList.vue'
import queryParamsFilter from '../global/queryParamsFilter'
import loader from '../global/loader'
import ProgramRoles from '../mixins/ProgramRoles'
import BaseSnackbarConfiguration from '../mixins/BaseSnackbarConfiguration'
import {mapActions, mapMutations, mapState, mapGetters} from 'vuex'
import {rateMask, amountMask} from '../directives/moneyMasks'
import UtilsBankAccount from '../mixins/UtilsBankAccount'
import CreateOrEditAsset from '../components/Program/Files/CreateOrEditAsset'
import BaseActionGroup from '../components/BaseActionGroup'
import permissions from "@/mixins/permissions";
import BaseAlert from '../components/BaseAlert'
import FormCreateOffer from './FormCreateOffer'
import BaseTextTooltip from './BaseTextTooltip'

export default {
  name: "TradeList",
  components: {
    CardDataTable,
    BaseDataBank,
    BaseDataTable,
    MovementDetails,
    BaseResumeInfos,
    BaseTemplateSuccess,
    BaseModal,
    BaseSnackbar,
    Warning,
    BaseFilters,
    AssetPostingList,
    CreateOrEditAsset,
    BaseActionGroup,
    BaseAlert,
    FormCreateOffer,
    BaseTextTooltip
  },
  mixins: [ProgramRoles, BaseSnackbarConfiguration, UtilsBankAccount, MovementsExport, permissions],
  props:{
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    headerTitle: {
      type: String,
      required: false,
      default: 'Negociações'
    },
    headerTitleDescription: {
      type: String,
      required: false,
      default: 'Acompanhe todas as suas operações.'
    },
    openModalOffer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    currentTab: 0,
    actionTradeList: [
      { id: 1, name: 'Cancelar', action: 'cancel', status: ['in-progress'], role: "owner" },
      { id: 2, name: 'Cancelar', action: 'cancel', status: ['done'], role: "admin" },
      { id: 3, name: 'Aprovar operação', action: 'approve-assignment', status: ['on-hold'], role: "owner" },
      { id: 4, name: 'Reprovar operação', action: 'reprove-assignment', status: ['on-hold'], role: "owner" }
    ],
    showWarningMessage: false,
    movementCategory: "assets",
    filterUpdatedDate: {
      start_date: '',
      end_date: '',
    },
    filterData: {
      filters: [],
      pagination: {
        sort_by: "date",
        sort_order: "desc"
      }
    },
    rateMask,
    amountMask,
    activeOfferDrawer: false,
    clearOfferForm: false,
    filter: {},
    isAssetModalActive: false,
    assetPostings: {},
    modalAssetDetail: {
      showIndeterminateProgress: false,
      topSlotContentTitle: "Detalhes do ativo",
      removeButtonClose: false,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: '',
      bottomButtonsAlign: "right",
      bottomSlotContentButtonCancelLabel: "Cancelar",
      bottomSlotContentButtonConfirmLabel: "Salvar",
      removeButtonCancel: true,
      removeButtonConfirm: true,
      onCancel: null,
      onConfirm: null
    },
    assetDetail: {},
    pagination: {
      page: 1,
      itemsPerPage: 25
    },
    currentTradeExpanded: null,
    currentTradeIdExpanded: '',
    offer: {
      ref: "createOffer",
      valid: false,
      to: {},
      hasSendSuccess: false,
      hasSendingOffer: false,
      currentOfferForApproveOrReprove: null
    },
    movement: {
      title: 'Criar proposta',
      actionsButtons: [
        {
          type: "text",
          text: "Cancelar",
          color: "primary",
          disabled: false,
          dispatchEvent: 'cancel'
        },
        {
          type: "raised",
          text: "Enviar",
          color: "primary",
          disabled: false,
          dispatchEvent: 'send'
        }
      ]
    },
    headers: [
      {
        text: 'Status',
        value: 'status.category.keyword',
        class: 'status',
        sortable: true
      },
      {
        text: 'Numero',
        value: 'number.keyword',
        class: 'number',
        sortable: true
      },
      {
        text: 'trading-partner',
        value: 'to.name.keyword',
        class: 'trading-partner',
        sortable: true,
        permissions: ["owner", "buyer", "seller"]
      },
      {
        text: 'seller',
        value: 'from.name.keyword',
        class: 'seller',
        sortable: true
      },
      {
        text: 'Participantes',
        value: 'parties',
        class: 'parties',
        sortable: true
      },
      {
        text: 'Data',
        value: 'date',
        class: 'date',
        sortable: true
      },
      {
        text: 'Valor',
        value: 'amount',
        class: 'amount',
        sortable: true
      },
      {
        text: '',
        value: 'actions',
        class: 'actions',
        sortable: false,
        permissions: ["trading-partner"]
      },
      {
        text: '',
        value: 'id',
        class: 'id',
        sortable: false
      }
    ],
    trades: [],
    groupPaymentsAccountsOfTrades: {},
    groupAssetsChildrenOfTrades: {},
    groupOffersChildrenOfTrades: {},
    approveOrReproveModal: {
      active: false,
      reason: '',
      validReason: false,
      action: '',
      showIndeterminateProgress: false,
      topSlotContentTitle: "",
      removeButtonClose: true,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: "",
      middleCheckAgree: null,
      bottomButtonsAlign: "right",
      bottomSlotContentButtonCancelLabel: "Cancelar",
      bottomSlotContentButtonConfirmLabel: "",
      removeButtonCancel: false,
      removeButtonConfirm: false,
      customEnableBottomButtonConfirmLabel: false
    },
    tradeActionModal: {
      active: false,
      reason: '',
      validReason: false,
      action: '',
      showIndeterminateProgress: false,
      topSlotContentTitle: "",
      removeButtonClose: false,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: "",
      middleCheckAgree: null,
      bottomButtonsAlign: "right",
      bottomSlotContentButtonCancelLabel: "Fechar",
      bottomSlotContentButtonConfirmLabel: "Confirmar",
      removeButtonCancel: false,
      removeButtonConfirm: false,
      customEnableBottomButtonConfirmLabel: false,
      onConfirm: null
    },
    filterAlert: 'Edite os filtros acima para mudar sua busca de negociações.',
    tableOfferHeaderFields: [],
    tableAssetHeaderFields: [],
    tableOfferHeaders: [],
    tableAssetHeaders: []
  }),
  computed: {
    ...mapState({
      loadPage: (state) => state.loadPage,
      programBankAccounts: (state) => state.programMovements.programBankAccounts,
      tradeList: (state) => state.programMovements.programMovements,
      programMovementDynamicAssetFields: state => state.programMovements.programMovementDynamicAssetFields,
      programInitiator: state => state.platformProgram.programInitiator,
      hideAllData: state => state.platformProgram.hideAllData
    }),
    ...mapGetters({
      activeRole: 'getActiveRole'
    }),
    disableExportData(){
      if(this.filterData.filters.length){
        let dates = this.filterData.filters.find(el => el.key == 'date' || el.key == 'post-date')
        let values = dates ? dates.values : []
        return values.length == 0 || this.getDiffDays(values) > 90 ? true : false
      }
      return true
    },
    headersAssetsChildren(){
      return [
        {
          text: 'Tipo',
          value: 'type',
          class: 'type',
        },
        {
          text: this.translateRoleType('buyer'),
          value: 'buyer',
          class: 'buyer',
        },
        {
          text: `CNPJ ${this.translateRoleType('buyer')}`,
          value: 'tax-number',
          class: 'tax-number',
        },
        {
          text: 'Número',
          value: 'number',
          class: 'number',
        },
        {
          text: 'Prazo (Dias)',
          value: 'maturity_in_days',
          class: 'maturity-in-days'
        },
        {
          text: 'Vencimento',
          value: 'post-date',
          class: 'post-date'
        },
        {
          text: 'Valor',
          value: 'amount',
          class: 'amount'
        },
        {
          text: '',
          value: 'details',
          class: 'text-right',
          permissions: ["owner"]
        }
      ]
    },
    primaryAccount() {
      return this.programBankAccounts.filter(account => account.primary === true)[0] || this.programBankAccounts[0]
    },
    hasBankAccount() {
      return this.programBankAccounts.length > 0
    },
    orderTrades() {
      return this.trades.map(trade => {
        const {
          id,
          status,
          number,
          seller,
          trading_partner,
          parties,
          date,
          amount,
          actions,
          expand,
          cancel_reason
        } = trade

        const tradeOrdered = {
          status: {
            name: status.name,
            statusColor: this.convertStatus(status.category),
            category: status.category,
          },
          number: (() => {
            return {
              value: number || '-',
              toogle: true,
              fontSize: 14,
              width: 120
            }
          })(),
          trading_partner: (() => {
            return {
              value: trading_partner || '-',
              toogle: true,
              fontSize: 14,
              width: 150
            }
          })(),
          seller: (() => {
            return {
              value: seller || '-',
              toogle: true,
              fontSize: 14,
              width: 150
            }
          })(),
          parties: (() => {
            return {
              value: parties ? parties.map(p => p.name).join(', ') : '-',
              toogle: true,
              fontSize: 14,
              width: 150
            }
          })(),
          date: this.$options.filters.getDate(date),
          amount: this.$options.filters.formatCurrency(amount),
          actions,
          id,
          tab: this.currentTab || 0,
          expand,
          cancel_reason
        }

        if (this.isTradingPartner()) {
          delete tradeOrdered.trading_partner
        }

        return tradeOrdered
      }) || []
    },
    getMovementSections() {
      return [
        {
          title: 'NEGOCIAÇÃO',
          subtitle: this.tradeDetails,
          value: 'trade'
        },
        {
          title: 'DADOS DE PAGAMENTO',
          value: 'data-payment'
        },
        {
          title: 'PROPOSTA',
          subtitle: 'Informe a taxa e o valor da proposta',
          value: 'offer'
        }
      ]
    },
    tradeDetails() {
      let number = "-"
      let seller = "-"

      if (this.currentTradeExpanded) {
        if (this.currentTradeExpanded.trade != undefined) {
          number = this.currentTradeExpanded.trade.number
          seller = this.currentTradeExpanded.trade.seller
        }
      }

      return `<span style="display: block;">
        Número <strong class="pl-2">${number}</strong>
      </span>
      <span style="display: block;" class="mb-3">
        Fornecedor <strong class="pl-2">${seller}</strong>
      </span>`

    },
    tradeOfferAssets() {
      let numberOfAssets = "-"
      let amount = "-"
      let typeAmount = "text"

      if (this.currentTradeExpanded) {

        if (this.currentTradeExpanded.trade != undefined) {

          const number_of_assets = this.currentTradeExpanded.trade.number_of_assets
          numberOfAssets = number_of_assets ? `${number_of_assets} ativos` : "-"
          amount = this.currentTradeExpanded.trade.amount
        } else {
          numberOfAssets = 0
          amount = 0.0
        }
      }

      return [
        {
          icon: 'request_quote',
          iconText: 'Quantidade',
          value: numberOfAssets,
          valueType: 'text'
        },
        {
          icon: 'attach_money',
          iconText: 'Valor total',
          value: this.$options.filters.formatCurrency(amount),
          valueType: typeAmount
        }
      ]
    },
    tradeOfferBankAccount() {
      let description = ""
      let bankValue = "-"
      let agencyValue = "-"
      let accountValue = "-"

      if (this.currentTradeExpanded) {

        if (this.currentTradeExpanded.trade != undefined) {
          bankValue = this.currentTradeExpanded.bank_account.bank
          agencyValue = this.currentTradeExpanded.bank_account.agency
          accountValue = this.currentTradeExpanded.bank_account.number
        }
      }

      return {
        description,
        bankValue,
        agencyValue,
        accountValue
      }
    },
    selectedOfferModal() {
      if (this.offer.currentOfferForApproveOrReprove) {
        return {
          ...this.offer.currentOfferForApproveOrReprove,
          reason: this.approveOrReproveModal.reason,
          action: this.approveOrReproveModal.action,
          trade: this.offer.currentTradeOffer
        }
      } else {
        return null
      }
    },
    tradeListTotalElements() {
      return this.tradeList.page ? this.tradeList.page.total_elements : 0
    },
    roleOrganizationId() {
      return this.getRoleOrganizationId()
    },
    createLinkToBankAccount() {
      const url = window.location.hostname
      let index = url.indexOf('.')
      const newUrl = url.slice(index + 1)
      return `https://${newUrl}/#/platform-organization/${this.roleOrganizationId}/bank-account`
    }
  },
  methods: {
    ...mapActions('programMovements', ['apiGetProgramMovementsChildren',
      'apiCreateProgramMovements', 'apiPatchProgramMovements', 'apiGetProgramBankAccounts', 'apiGetSchemas', 'apiUpdateProgramMovement']),
    ...mapActions('programFilters', ['apiPostMovementsFilters']),
    ...mapMutations(["setDisableNavigation"]),
    ...mapMutations('programMovements', ['setProgramMovements']),
    disableCancelOffer(status){
      return (status != 'in-progress' && status != 'on-hold') || this.activeOfferDrawer ? true : false
    },
    splitProp(key) {
      let splittedKey = key.split('.')
      return splittedKey
    },
    parseHeadersOffer(){
      let fields = this.tableOfferHeaderFields.data.fields.sort((a, b) => a.sequence - b.sequence).map(header => {
        return {
          hidden: header.hidden,
          text: header.name,
          value: header.key,
          class: header.key
        }
      })
      fields.push({
        hidden: false,
        text: '',
        value: 'admin-config',
        class: '',
        sortable: false
      })
      this.tableOfferHeaders = [...fields]
    },
    parseHeadersAsset(){
      let fields = this.tableAssetHeaderFields.data.fields.sort((a, b) => a.sequence - b.sequence).map(header => {
        return {
          hidden: header.hidden,
          text: header.name,
          value: header.key,
          class: header.key,
          datasource: header.datasource
        }
      })
      fields.push({
        hidden: false,
        text: '',
        value: 'admin-config',
        class: '',
        sortable: false
      })
      this.tableAssetHeaders = [...fields]
    },
    isAmountValid(value) {
      return this.parseNumberDecimal(value) > 0 || 'Valor inválido'
    },
    getEditedFields(fields) {
      let fieldsString = ''
      fields.forEach(field =>
          field.read_only == 'false' && field.key != 'status.name' && field.key != 'date' ? fieldsString +=  `${field.keyWithPrefix},` : '')
      return fieldsString.replace(/,\s*$/, "");
    },
    isSortable(header){
      return header.sortable
    },
    disableOfferActions(offers, currentOffer){
      return offers.findIndex(offer => offer.data.status.category === 'done') > -1 || currentOffer.status.category === 'error'
    },
    getActionTradeList(tradeStatus){
      return this.actionTradeList.filter(action => (action.role === this.activeRole || (action.role === 'admin' && this.userIsAdmin)) && action.status.includes(tradeStatus)
      )
    },
    async onConfirmTradeAction(trade, reason, action){
      const feedbackMsg = {
        "cancel": ["cancelada", "cancelar"],
        "approve": ["aprovada", "aprovar"]
      }
      const tradeExternalId = this.trades.filter(tradeObject => tradeObject.id === trade.id)[0]['external-id']
      const data = {
        action: action,
        data: {
          id: trade.id,
          date: trade.date.split('/').reverse().join('-'),
          number: trade.number.value,
          reason: reason || ''
        }
      }
      if (action === 'approve'){
        Object.assign(data.data, {
          ...data.data,
          'external-id': tradeExternalId,
          category: 'trade',
          movement_action: 'approve'
        })
      }
      try {
        await this.apiPatchProgramMovements({category: 'trades', data})
        this.showFeedback({
          message: `Negociação ${feedbackMsg[action][0]} com sucesso`,
          color: 'success'
        })
      } catch(e){
        this.showFeedback({
          message: `Erro ao ${feedbackMsg[action][1]} a negociação`,
          color: 'error'
        })
      }finally {
        this.onCancelTradeAction()
        setTimeout(async()=> {
          await this.getTradesMovements()
          await this.tradeExpanded({value: true, item: trade})
        }, 5000)
      }
    },
    onCancelTradeAction(){
      Object.assign(this.tradeActionModal, {
        active: false,
        action: '',
        reason: '',
        customEnableBottomButtonConfirmLabel: null
      })
    },
    onClickTradeAction(action){
      let optionsActionList = {
        cancel: () => {
          Object.assign(this.tradeActionModal, {
            active: true,
            action: 'cancel',
            topSlotContentTitle: "Cancelar negociação",
            middleSlotContentText: `<p>Ao prosseguir, a ação não poderá ser desfeita.<br><br>Os ativos ficarão disponíveis novamente para negociações.<br>Deseja cancelar esta negociação?</p>`,
            bottomSlotContentButtonConfirmLabel: "Cancelar negociação",
            customEnableBottomButtonConfirmLabel: false,
            onConfirm: () => this.onConfirmTradeAction(action.currentObject, this.tradeActionModal.reason, this.tradeActionModal.action),
            onCancel: () => this.onCancelTradeAction()
          })
        },
        'reprove-assignment': () => {
          Object.assign(this.tradeActionModal, {
            active: true,
            action: 'cancel',
            topSlotContentTitle: "Reprovar operação",
            middleSlotContentText: `<p>Ao prosseguir, a ação não poderá ser desfeita.<br><br>Os ativos ficarão disponíveis novamente para negociações.<br>Deseja reprovar essa negociação?</p>`,
            bottomSlotContentButtonConfirmLabel: "Reprovar",
            customEnableBottomButtonConfirmLabel: false,
            onConfirm: () => this.onConfirmTradeAction(action.currentObject, this.tradeActionModal.reason, this.tradeActionModal.action),
            onCancel: () => this.onCancelTradeAction()
          })
        },
        'approve-assignment': () => {
          Object.assign(this.tradeActionModal, {
            active: true,
            action: 'approve',
            topSlotContentTitle: "Aprovar operação",
            middleSlotContentText: `<p>Ao prosseguir, a ação não poderá ser desfeita.<br><br>Deseja aprovar essa negociação?</p>`,
            bottomSlotContentButtonConfirmLabel: "Aprovar",
            customEnableBottomButtonConfirmLabel: true,
            onConfirm: () => this.onConfirmTradeAction(action.currentObject, null, this.tradeActionModal.action),
            onCancel: () => this.onCancelTradeAction()
          })
        }
      }
      optionsActionList[action.action]()
    },
    closeApproveOrReproveOffer() {
      Object.assign(this.approveOrReproveModal, {
        active: false,
        action: '',
        reason: '',
        topSlotContentTitle: "",
        middleSlotContentText: ``,
        bottomSlotContentButtonConfirmLabel: "",
        customEnableBottomButtonConfirmLabel: null
      })

      this.$set(this.offer, 'currentOfferForApproveOrReprove', null)
    },
    showOfferOnHold(offer){
      return !this.isTradingPartner() && offer.category === 'on-hold' ? false : true
    },
    handleClickApproveOrReproveOffer(action, offer, tradeId) {
      const actions = {
        approve: () => {
          Object.assign(this.approveOrReproveModal, {
            active: true,
            action: 'approve',
            topSlotContentTitle: "Aceitar Proposta",
            middleSlotContentText: `<p>Ao prosseguir, a ação não poderá ser desfeita.<br> Deseja aceitar esta proposta?</p>`,
            bottomSlotContentButtonConfirmLabel: "Aceitar Proposta",
            customEnableBottomButtonConfirmLabel: null
          })
        },
        reprove: () => {
          Object.assign(this.approveOrReproveModal, {
            active: true,
            action: 'reprove',
            topSlotContentTitle: "Recusar Proposta",
            middleSlotContentText: ``,
            bottomSlotContentButtonConfirmLabel: "Recusar Proposta",
            customEnableBottomButtonConfirmLabel: false
          })
        },
        cancel: () => {
          Object.assign(this.approveOrReproveModal, {
            active: true,
            action: 'cancel',
            topSlotContentTitle: "Cancelar Proposta",
            middleSlotContentText: `<p>Ao prosseguir, a ação não poderá ser desfeita.<br> Deseja cancelar esta proposta?</p>`,
            bottomSlotContentButtonConfirmLabel: "Cancelar Proposta",
            customEnableBottomButtonConfirmLabel: null
          })
        },
        update: () => {
          this.handleClickTrade({dispatchEvent: { type: 'click', value: {id: tradeId} }}, {isEditing: true, offer})
        },
      }
      actions[action]()
      this.$set(this.offer, 'currentOfferForApproveOrReprove', offer)
      const trade = this.trades.filter(trade => trade.id === tradeId)
      this.$set(this.offer, 'currentTradeOffer', trade[0])
    },
    handleInputReasonIsError(value) {
      Object.assign(this.approveOrReproveModal, {
        customEnableBottomButtonConfirmLabel: !value
      })
    },
    async createApproveOrReproveOffer(offer) {
      this.approveOrReproveModal.showIndeterminateProgress = true
      const {id, action, reason, date, trade} = offer
      const feedbackMsg = {
        "approve": ["Aceite", "aceitar"],
        "reprove": ["Recusa", "recusar"],
        "cancel": ["Cancelamento", "cancelar"]
      }

      let isoDate = date.split('/').reverse().join('-');

      try {
        const approveOrReproveAction = {
          action: action,
          data: {
            id: id,
            movement_action: action,
            category: 'offer',
            date: isoDate,
            'external-id': offer['external-id'],
            parent: {
              id: trade.id,
              category: "trade",
              date: trade.date,
              'external-id': trade['external-id']
            }
          }
        }

        if (reason) {
          approveOrReproveAction.data.reason = reason
        }
        await this.apiPatchProgramMovements({category: 'offers', data: approveOrReproveAction})
        this.showFeedback({
          message: `${feedbackMsg[action][0]} da proposta feito com sucesso`,
          color: 'success'
        })
      } catch (error) {
        this.showFeedback({
          message: `Erro ao ${feedbackMsg[action][1]} a proposta`,
          color: 'error'
        })

      } finally {
        setTimeout(() => {
          this.closeApproveOrReproveOffer()
          this.approveOrReproveModal.showIndeterminateProgress = false
        }, 10000)
        setTimeout(async()=> {
          this.currentTab = 1
          await this.getTradesMovements()
          await this.tradeExpanded({value: true, item: trade})
        }, 5000)
      }
    },
    convertRate(rateType) {

      let types = {
        'monthly': 'a.m',
        'daily': 'a.d',
        'yearly': 'a.a',
        'cdi': 'CDI',
      }

      return types[rateType] || rateType
    },
    convertStatus(value) {
      const status = {
        'in-progress': "progress",
        done: "running",
        created: "ok",
        error: "cancelled",
        "on-hold": "pending"
      }
      return status[value]
    },
    closeMovementDetail() {
      this.activeOfferDrawer = false
      this.setDisableNavigation(false)
      this.currentTradeExpanded = null
      this.offer = Object.assign({}, { hasSendSuccess: false, currentOfferForApproveOrReprove: null })
      this.clearOfferForm = true
    },
    async openAssetDetail(asset, tradeId) {
      await this.apiGetSchemas({ scope: 'trade-assets' })
      const currentAsset = this.groupAssetsChildrenOfTrades[tradeId].item.filter(a => a.data.id == asset.id)
      const mergedAsset = {...currentAsset[0]}
      this.assetDetail = JSON.parse(JSON.stringify({ ...mergedAsset , ...mergedAsset['data']}))

      this.isAssetModalActive = true
      this.assetPostings = asset
    },
    updateModalActive(value) {
      this.isAssetModalActive = value
    },
    onCancelForm() {
      this.isAssetModalActive = false
    },
    async handleClickTrade(CustomEv, editOffer = {}) {
      this.offer = Object.assign({}, {hasSendSuccess: false})
      this.clearOfferForm = true

      if(editOffer.isEditing == true){
        let newOffer = {
          ...editOffer.offer,
          ref: "createOffer",
          valid: false,
          hasSendSuccess: false,
          hasSendingOffer: false
        }
        Object.assign(this.offer, newOffer)
      }

      try {
        const {value: trade} = CustomEv.dispatchEvent

        let matchIndex = -1
        let matchTrade = null

        for (let i = 0; i < this.trades.length; i++) {
          if (this.trades[i].id === trade.id) {
            matchIndex = i
            matchTrade = Object.assign({}, this.trades[i])
            break
          }
        }

        if (matchIndex < 0 || !matchTrade) {
          throw `handleClickTrade: trade clicked with id: ${trade.id} does not exists in trades`
        }

        matchTrade.actions[0].disabled = true
        Object.assign(this.trades[matchIndex], matchTrade)

        await this.tradeExpanded({
          value: true,
          item: {...trade},
          from: matchTrade.from,
          parent: {date: matchTrade.date, 'external-id': matchTrade['external-id']}
        })
        await this.apiGetSchemas({ scope: 'offer' })

        this.activeOfferDrawer = true
        this.setDisableNavigation(true)
        matchTrade.actions[0].disabled = false

        Object.assign(this.trades[matchIndex], matchTrade)

      } catch (error) {
        throw error
      }
    },
    orderGroupAssetsChildrenOfTrades(items) {
      return items.map(item => {
        const {type, to, from, data} = item
        return {
          type,
          to,
          from,
          ...data
        }
      })
    },
    executeDatasourceCode(assetData, tradeData, datasource){
      var asset = assetData
      var trade = this.tradeList.content.filter(trade => trade.data.id === tradeData.id)[0] || {}
      return eval(datasource)
    },
    filterOffers(data){
      return data.filter(elem => elem.status.category !== 'on-hold' || (this.isTradingPartner() && elem.status.category === 'on-hold'))
    },
    orderGroupOffersChildrenOfTrades(items) {
       return items.map(item => {
        const {type, to, from, data} = item
        return {
          type,
          to,
          from,
          ...data
        }
      })
    },
    parseTax(value){
      return this.$options.filters.formatCurrency(this.convertToPercentage(value), false, 4) + '%'
    },
    convertToPercentage(value) {
      return parseFloat(value) * 100
    },
    convertToDecimal(value) {
      return value / 100
    },
    async changeSort(column) {
      if (this.filterData.pagination.sort_by === column) {
        this.filterData.pagination.sort_order = this.filterData.pagination.sort_order == 'desc'
          ? 'asc' : 'desc'
      } else {
        this.filterData.pagination.sort_by = column
        this.filterData.pagination.sort_order = 'desc'
      }

      this.filterData.filters.sortBy = this.filter.sortBy

      this.getTradesMovements()
    },
    async filterTrades(filters) {
      this.pagination.page = 1

      const newPagination = this.filterData.pagination.sort_by ? this.filterData.pagination : { page: this.pagination.page, size: this.pagination.itemsPerPage }

      Object.assign(this.filterData, {
        ...filters,
        pagination: newPagination
      })

      this.getTradesMovements()
    },
    updateFilter(value, prop) {
      this.filter[prop] = value
      this.filterUpdatedDate[prop] = this.filter[prop]
    },
    async getTradesMovements() {
      try {
        const trades = []
        let result = null
        const data = this.filter

        if(!this.isAdminEditing) {
          await this.apiGetSchemas({ scope: 'offer' })
        }

        result = await this.apiPostMovementsFilters(this.filterData)
        this.setProgramMovements(result.data)

        const {content: contents} = result.data

        contents.forEach(content => {
          trades.push(Object.assign(
            {
              from: content.from,
              seller: content.from ? content.from.name : '-',
              trading_partner: content.to ? content.to.name : '-',
              actions: this.isTradingPartner() ? [
                {
                  text: 'Criar Proposta',
                  type: 'text',
                  dispatchEvent: 'click',
                  disabled: content.data.status.category !== 'in-progress'
                }
              ] : [],
              expand: false
            },
            {...content.data}
          ))
        })
        this.trades = trades
      } catch (error) {
        throw error
      }
    },
    async getTradesMovementsChildren(categoryChildren, tradeId) {
      try {
        const tradeChildren = this.getTradeChildren(categoryChildren)
        const tradeChildrenFilterOfTradeMovement = tradeChildren[tradeId].filter

        const filterProps = tradeChildrenFilterOfTradeMovement.getFilterProps()

        const {start_date, end_date, ...queryParams} = filterProps

        queryParams.size = 200
        const data = {
          routeParams: {
            category: 'trades',
            id: tradeId
          },
          queryParams
        }


        const result = await this.apiGetProgramMovementsChildren(data)

        const {content: newTradeChildren} = result.data

        const tradeChildrenToUpdate = {
          [tradeId]: {
            filter: tradeChildrenFilterOfTradeMovement,
            item: newTradeChildren
          }
        }

        this.updateTradeChildren(categoryChildren, tradeChildrenToUpdate)
        return result

      } catch (error) {
        throw error
      }

    },
    createTradeMovementsChildren(category, tradeId) {
      const filterChildren = new queryParamsFilter.createQueryParamsFilter()
      filterChildren.setAdditionalProps({category})

      const tradeChildren = {
        [tradeId]: {filter: filterChildren, item: []}
      }

      this.updateTradeChildren(category, tradeChildren)
    },
    addMenuList(menu){
      if(this.actionTradeList.findIndex(action => action.id === menu.id) == -1){
        this.actionTradeList.push(menu)
      }
    },
    async tradeExpanded(trade) {

      try {
        this.parseHeadersOffer()
        this.parseHeadersAsset()
        const {value: isExpanded} = trade
        if (isExpanded) {
          const tradeId = trade.item.id
          this.currentTradeIdExpanded = tradeId

          let currentTrade = this.trades.filter(m => m.id == tradeId)[0];
          const currentBankAccount = this.primaryAccount
          let actionMenuOption = [
            { id: 5, name: 'Aprovar operação', action: 'approve-assignment', status: ['on-hold'], role: currentTrade.must_approve_by_role },
            { id: 6, name: 'Reprovar operação', action: 'reprove-assignment', status: ['on-hold'], role: currentTrade.must_approve_by_role },
          ]

          this.createTradeMovementsChildren('asset', tradeId)
          this.createTradeMovementsChildren('offer', tradeId)
          this.createTradeMovementsChildren('payment', tradeId)

          if(currentTrade.must_approve_by_role){
            actionMenuOption.forEach(menu => {
              this.addMenuList(menu)
            })
          }

          await this.getTradesMovementsChildren('asset', tradeId)
          await this.getTradesMovementsChildren('offer', tradeId)
          await this.getTradesMovementsChildren('payment', tradeId)

          this.currentTradeExpanded = {
            rate: null,
            rate_type: null,
            effective_rate: null,
            trade: {
              id: currentTrade.id,
              date: currentTrade.date,
              'external-id': currentTrade['external-id'],
              number: currentTrade.number,
              seller: currentTrade.seller,
              amount: currentTrade.amount,
              from: currentTrade.from,
              number_of_assets: this.groupAssetsChildrenOfTrades[currentTrade.id].item.length
            },
            bank_account: {
              number: currentBankAccount ? this.getNumberAndDigit(currentBankAccount) : '-',
              agency: currentBankAccount ? this.getAgencyAndDigit(currentBankAccount) : '-',
              bank: currentBankAccount ? currentBankAccount.bank_code : '-'
            }
          }

          this.$forceUpdate()
        } else {
          this.currentTab = 0
        }
      } catch (error) {
        throw error
      }
    },
    async updateTradeChildrenPagination(newPagination, category, tradeId) {
      if (tradeId) {
        const tradeChildren = this.getTradeChildren(category)

        tradeChildren[tradeId].filter.page = newPagination.page;
        tradeChildren[tradeId].filter.size = newPagination.itemsPerPage;

        const result = await this.getTradesMovementsChildren(category, tradeId)

        this.$forceUpdate()

        return result
      }
    },
    updatePagination(newPagination) {
      try {
        const {page, itemsPerPage} = newPagination

        Object.assign(this.pagination, {
          page,
          itemsPerPage
        })

        this.filter.page = page;
        this.filter.size = itemsPerPage;
        Object.assign(this.filterData.pagination, {page, size: itemsPerPage})
        this.getTradesMovements()

      } catch (error) {
        throw error
      }
    },
    updateTradeChildren(category, children) {
      try {
        const groupOfChildrenType = {
          asset: (children) => {
            Object.assign(this.groupAssetsChildrenOfTrades, children)
          },
          offer: (children) => {
            Object.assign(this.groupOffersChildrenOfTrades, children)
          },
          payment: (children) => {
            Object.assign(this.groupPaymentsAccountsOfTrades, children)
          }
        }
        const keysOfGroupOfChildrenType = Object.keys(groupOfChildrenType)

        if (keysOfGroupOfChildrenType.indexOf(category) === -1) {
          throw `updateTradeChildren: category ${category} does not exists in groupOfChildrenType. Expect ${keysOfGroupOfChildrenType}`
        }

        groupOfChildrenType[category](children)

      } catch (error) {
        throw error
      }
    },
    getTradeChildren(category) {
      try {
        const groupOfChildrenType = {
          asset: () => this.groupAssetsChildrenOfTrades,
          offer: () => this.groupOffersChildrenOfTrades,
          payment: () => this.groupPaymentsAccountsOfTrades
        }
        const keysOfGroupOfChildrenType = Object.keys(groupOfChildrenType)

        if (keysOfGroupOfChildrenType.indexOf(category) === -1) {
          throw `getTradeChildren: category ${category} does not exists in groupOfChildrenType. Expect ${keysOfGroupOfChildrenType}`
        }

        return groupOfChildrenType[category]()

      } catch (error) {
        throw error
      }
    },
    handleClickMovementDetails(customEv) {
      const {dispatchEvent: eventName} = customEv

      const eventActions = {
        'send': async () => {
          try {
            Object.assign(this.offer, {hasSendingOffer: true})

            await this.sendOffer()

            Object.assign(this.offer, {
              hasSendSuccess: true,
              hasSendingOffer: false
            })

            setTimeout(async()=> {
              this.currentTab = 1
              await this.getTradesMovements()
              await this.tradeExpanded({value: true, item: this.currentTradeExpanded.trade})
            }, 5000)

          } catch (error) {
            Object.assign(this.offer, {
              hasSendSuccess: false,
              hasSendingOffer: false
            })
            throw error
          }
        },
        'cancel': async () => {
          this.closeMovementDetail()
        }
      }

      eventActions[eventName]()
    },
    async sendOffer() {
      try {
        let offerObject = {}

        this.programMovementDynamicAssetFields.forEach(({key}) => {
          if(key.includes('rate') && key !== 'rate_type'){
            offerObject = {
              ...offerObject,
              [key]: this.convertToDecimal(this.parseNumberDecimal(this.offer[key])).toFixed(6)
            }
          } else if (key.includes('amount') || key.includes('emolument')) {
            offerObject = {
              ...offerObject,
              [key]: this.parseNumberDecimal(this.offer[key]).toFixed(2)
            }
          } else {
            offerObject = {
              ...offerObject,
              [key]: this.offer[key] == undefined ? '' : this.offer[key]
            }
          }
        })
        const {valid, to, hasSendingOffer, hasSendSuccess, currentOfferForApproveOrReprove, ref, ...offerData} = offerObject
        const data = {
          id: "offer",
          to: this.currentTradeExpanded.trade.from,
          parent: {
            id: this.currentTradeExpanded.trade.id,
            category: "trade",
            date: this.currentTradeExpanded.trade.date,
            'external-id': this.currentTradeExpanded.trade['external-id']
          },
          data: {
            ...offerData,
            bank_account: {
              ...this.primaryAccount
            }
          }
        }
        if(this.offer.id){
          data.data.id = this.offer.id
          data.data['external-id'] = this.offer.id
          data.data.date = this.offer.date
          await this.apiUpdateProgramMovement({
            type: 'offers',
            data: data,
            id: this.offer.id,
            fields: this.getEditedFields(this.programMovementDynamicAssetFields),
            scope: 'offer'
          })
        } else {
          await this.apiCreateProgramMovements({
            category: 'offers',
            data: data
          })
        }

        this.showFeedback({
          message: `Envio da proposta feito com sucesso`,
          color: 'success'
        })

      } catch (error) {

        this.showFeedback({
          message: `Erro ao enviar a proposta `,
          color: 'error'
        })

        throw error
      }
    },
    toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    },
    minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - days))
    },
    setPaginationToFilter() {
      this.filter.page = this.pagination.page;
      this.filter.size = this.pagination.itemsPerPage;
    },
     filtersToReport(filtersList) {
      let filters = {}
      filtersList.forEach(filter => {
        filters = { ...filters, [`filter.${filter.key}`] : filter.values.join()}
      })
      return filters
    },
    async executeExportData() {
      const filtersReport = {}
      if(this.filterData.filters.length > 0) {
        Object.assign(filtersReport, this.filtersToReport(this.filterData.filters))
      }else {
        let filterData = [{
          key: "date",
          values: [
          this.toLocalDate(this.minusDays(new Date(), '30')).toISOString().substr(0, 10),
          this.toLocalDate(new Date()).toISOString().substr(0, 10)
          ],
        }]
        Object.assign(filtersReport, this.filtersToReport(filterData))
      }

      const data = {
        action: 'trade-report',
        data: {
          ...filtersReport,
          'program-id': this.programInitiator,
          category: 'trade',
          'user-tax-number': this.getActiveOrganization() ? this.getActiveOrganization().tax_number : '',
        }
      }

      await this.apiPatchProgramMovements({category: 'trades', data})
      this.showWarningMessage = true
    },
    changeHandleInputForm(value){
      if(!this.hasBankAccount){
        value = false
      }
      Object.assign(this.movement.actionsButtons[1], {disabled: !value})
    },
    changeClearOfferForm(value) {
      this.clearOfferForm = value
    },
    changeOfferObject(value) {
      Object.assign(this.offer, value)
    }
  },
  async created() {
    this.apiGetProgramBankAccounts()
    this.filter.category = 'trades'
    this.filter['start_date'] = this.toLocalDate(
      this.minusDays(new Date(), 30)).toISOString().substr(0, 10);
    this.filter['end_date'] = this.toLocalDate(new Date()).toISOString().substr(0, 10);
    this.setPaginationToFilter()
    this.filterUpdatedDate.start_date = this.filter.start_date
    this.filterUpdatedDate.end_date = this.filter.end_date
    this.tableAssetHeaderFields = await this.apiGetSchemas({ scope: 'trade-asset-table' })
    this.tableOfferHeaderFields = await this.apiGetSchemas({ scope: 'offer-table' })
  },
  beforeRouteLeave(to, from, next){
    this.setProgramMovements([])
    next()
  }
}
</script>
<style lang="scss">

.trade-table-dinamic {
  th {
    white-space: nowrap;
    padding: 15px !important;
  }
}

.platform-trades .trade-list__notification {
 font-size: 16px;
  margin: -40px 0 18px -24px;
  width: calc(100% + 48px);
}

.platform-trades .header-sortable {
  cursor: pointer;
}

.platform-trades .number {
  width: 120px;
}

.platform-trades .date {
  width: 95px;
}

.platform-trades .amount {
  width: 120px;
}

.platform-trades .id {
  width: 50px;
  padding: 0 !important;
}

.platform-trades .actions {
  width: 87px;
  padding: 0 !important;
}

.platform-trades .status {
  width: 147px;
}

.platform-trades .text-truncate {
  max-width: 190px;
}

.platform-trades .parties,
.platform-trades .seller,
.platform-trades .trading-partner {
  min-width: 40px;
  flex: 1 1 0;
}

.platform-trades__header__actions {
  display: flex;
  align-items: center;
}

.platform-trades .tabs .tab--text {
  font-weight: 700;
  font-size: 14px;
}

.platform-trades__payments {
  background-color: rgb(244, 245, 248);
}

.platform-trades__item--status {
  color: var(--custom-theme-black-054) !important;
  display: inline-block;
  font-size: 10px !important;
  height: 24px !important;
  min-width: 120px;
  margin: 4px !important;
  text-align: center;
}

.platform-trades__item--status :first-child {
  height: inherit;
}

</style>
