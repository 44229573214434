<template>
  <div class="receipt-list">
    <BaseContainer
      :active-spacer="true"
      :permission-list="['owner']"
    >
      <template v-slot:header-title>
        {{ headerTitle }}
      </template>
      <template v-slot:header-title-description>
        {{ headerTitleDescription }}
      </template>

      <div class="receipt-list__header__actions">
        <BaseFilters
          scope="receipts"
          filter-scope="receipts"
          :pagination="pagination"
          :load-page="loadPage"
          :is-admin-editing="isAdminEditing"
          @executeActionFilter="filterReceipts"
        />
        <v-spacer />
        <ButtonExportData
          class="d-flex align-self-start ml-4"
          :class="{ 'app-apply-blur' : isAdminEditing }"
          :load-page="loadPage"
          :data-movements-to-export="dataMovementsToExport"
          :file-name="getFileName(filter.category)"
          :is-exported="isExported"
          :is-loading-data="isLoadingData"
          :disable-button="disableExportData"
          @executeExportData="exportData(filter, filterData)"
        />
      </div>
      <BaseAlert :class="{ 'app-apply-blur' : isAdminEditing }">
        <b>Filtro aplicado:</b> {{ filterAlert }}
      </BaseAlert>
      <BaseDataTable
        :table-model="selected"
        :headers="headers"
        background-color="white"
        :hide-headers="true"
        :items="dataReceiptList"
        item-key="id"
        classes="elevation-2 mt-6"
        :class="{ 'app-apply-blur' : isAdminEditing }"
        :total-items="receiptListTotalElements"
        :options="pagination"
        :expand="expand"
        @update-pagination="updatePagination"
        @update-model="updateModel"
      >
        <template v-slot:inject-header="{headers}">
          <tr>
            <th
              v-for="(header, index) in headers.props.headers"
              :key="index"
              style="white-space: nowrap;"
              :hidden="header.permissions ? !userHasProgramRoles(header.permissions) : false"
              :class="[filterData.pagination.sort_order, header.value === filterData.pagination.sort_by ? 'active' : '', header.class]"
              @click="changeSort(header.value)"
            >
              {{ translateRoleType(header.text) }}
              <v-icon
                class="v-data-table-header__icon"
                small
              >
                arrow_upward
              </v-icon>
            </th>
          </tr>
        </template>

        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.index"
          >
            <td>
              <v-chip
                class="receipt-list__item--status font-weight-medium"
                :color="props.item.statusColor"
              >
                {{ props.item.status.name }}
              </v-chip>
            </td>
            <td class="text-center">
              {{ props.item.date | getDate }}
            </td>
            <td class="text-right">
              {{ props.item.number }}
            </td>
            <td class="text-right">
              {{ props.item.purchase_order }}
            </td>
            <td class="text-right">
              {{ props.item.reference_document }}
            </td>
            <td>{{ props.item.type ? props.item.type.title : '-' }}</td>
            <td v-if="isOwner()">
              {{ props.item.seller }}
            </td>
            <td>{{ props.item.buyer }}</td>
            <td>{{ props.item['post-date'] | getDate }}</td>
            <td class="text-right">
              {{ props.item.amount | formatCurrency }}
            </td>
            <td
              v-if="isOwner()"
              class="text-right"
            >
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 text-decoration-underline"
                button-class="app-font-regular"
                @click="openReceiptDetail(props.item)"
              >
                + detalhes
              </BaseButton>
            </td>
          </tr>
        </template>
        <template v-slot:inject-expand="scopedItems">
          <AssetPostingList :selected-movement="scopedItems.items.item" />
        </template>
      </BaseDataTable>
    </BaseContainer>

    <v-dialog
      :value="isModalActive"
      persistent
      transition="app-transition-to-right"
      content-class="app-transition-to-right-additional-configs"
      @input="updateModalActive"
    >
      <BaseModal
        style="height: 100vh; flex-wrap: nowrap;"
        :show-indeterminate-progress="modalReceiptDetail.showIndeterminateProgress"
        :top-slot-content-title="modalReceiptDetail.topSlotContentTitle"
        :remove-button-close="modalReceiptDetail.removeButtonClose"
        :middle-slot-content-text-highlight="modalReceiptDetail.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalReceiptDetail.middleSlotContentText"
        :middle-check-agree="modalReceiptDetail.middleCheckAgree"
        :bottom-buttons-align="modalReceiptDetail.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalReceiptDetail.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalReceiptDetail.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalReceiptDetail.removeButtonCancel"
        :remove-button-confirm="modalReceiptDetail.removeButtonConfirm"
        body-background-color="#F8F8F8"
        @on-cancel="onCancelForm"
        @on-confirm="() => null"
      >
        <div
          class="mt-6"
          style="max-width: 645px; margin: 0 auto;"
        >
          <CreateOrEditAsset
            context-actions="edit"
            :header-title="'Detalhes do recebimento'"
            :header-title-description="'Veja ou edite dados do recebimento'"
            :asset-object="receiptDetail"
            :clear-form="!isModalActive"
            scope-schema="receipts"
            scope-category="receipts"
            scope-category-id="receipt"
            @updateAssetList="getReceiptMovements"
          />
          <BaseCard
            :base-card-container-classes="'mt-4'"
            :has-main-container="true"
            :is-header-title-description="true"
          >
            <template v-slot:header-title>
              <div class="pl-4">
                Movimentos
              </div>
            </template>
            <template v-slot:header-title-description>
              <div class="pl-4 mb-4">
                Veja movimentos contábeis relacionados ao ativo
              </div>
            </template>
            <AssetPostingList
              :selected-movement="receiptPostings"
              :movement-category="movementCategory"
            />
          </BaseCard>
        </div>
      </BaseModal>
    </v-dialog>

    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import loader from '../../global/loader'
import BaseDataTable from '../BaseDataTable.vue'
import BaseModal from '../BaseModal'
import ProgramRoles from "../../mixins/ProgramRoles";
import MovementsExport from "../../mixins/MovementsExport";
import AssetPostingList from "../Program/AssetPostingList"
import {amountMask} from "@/directives/moneyMasks";
import BaseSnackbarConfiguration from "@/mixins/BaseSnackbarConfiguration";
import BaseSnackbar from '../BaseSnackbar.vue'
import BaseFilters from '../BaseFilters'
import BaseAlert from '../BaseAlert'
import CreateOrEditAsset from '../Program/Files/CreateOrEditAsset'

export default {
  components: {BaseDataTable, AssetPostingList, BaseModal, BaseFilters, BaseSnackbar, BaseAlert, CreateOrEditAsset},
  mixins: [ProgramRoles, BaseSnackbarConfiguration, MovementsExport],
  props:{
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    headerTitle: {
      type: String,
      required: false,
      default: 'Recebimentos'
    },
    headerTitleDescription: {
      type: String,
      required: false,
      default: 'Lista de movimentos de entrada e saída.'
    }
  },
  data: () => ({
    availableAmount: 0,
    receiptPostings: {},
    isModalActive: false,
    amountMask,
    movementCategory: 'receipts',
    expand: false,
    selected: [],
    receiptDetail: {
      valid: false,
      noFocus: true,
      buyer: '',
      number: '',
      'post-date': '',
      amount: '',
      type: {
        title: ''
      }
    },
    filterData: {
      filters: [],
      category: 'receipts',
      pagination: {}
    },
    filter: {
      category: "receipts",
      start_date: '',
      end_date: '',
      page: 0,
      size: 0
    },
    pagination: {
      page: 1,
      itemsPerPage: 25
    },
    headers: [
      {text: 'Status', value: 'status.name', class: 'status'},
      {text: 'Data', value: 'date', class: 'date'},
      {text: 'Número', value: 'number.keyword', class: 'number'},
      {text: 'P.O', value: 'purchase-order.keyword', class: 'purchase_order'},
      {text: 'Ref Doc', value: 'reference_document.keyword', class: 'reference_document'},
      {text: 'Tipo', value: 'type.title', class: 'type'},
      {text: 'seller', value: 'from.name.keyword', class: 'seller', permissions: ["owner"]},
      {text: 'buyer', value: 'to.name.keyword', class: 'buyer'},
      {text: 'Vencimento', value: 'post-date', class: 'post-date'},
      {text: 'Valor', value: 'amount', class: 'amount text-right'},
      {text: '', value: '', class: '', permissions: ["owner"]}
    ],
    search: '',
    modalReceiptDetail: {
      showIndeterminateProgress: false,
      topSlotContentTitle: "Detalhes do recebimento",
      removeButtonClose: false,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: '',
      bottomButtonsAlign: "right",
      bottomSlotContentButtonCancelLabel: "Cancelar",
      bottomSlotContentButtonConfirmLabel: "Salvar",
      removeButtonCancel: true,
      removeButtonConfirm: true,
      onCancel: null,
      onConfirm: null
    },
    filterAlert: 'Edite os filtros acima para mudar sua busca de ativos.'
  }),
  computed: {
    ...mapState({
      receiptList: (state) => {
        return state.programMovements.programMovements
      },
      loadPage: (state) => state.loadPage
    }),
    dataReceiptList() {
      return this.receiptList.content ? this.receiptList.content.map(element => {
        const {type, to, from, data} = element
        return {
          ...data,
          statusColor: this.convertStatus(data.status.category),
          isExpanded: false,
          type: type,
          seller: from ? (from.name ? from.name : this.$options.filters.maskCPForCNPJ(
            from.data['tax-number'])) : '-',
          buyer: to ? (to.name ? to.name : this.$options.filters.maskCPForCNPJ(to.data['tax-number']))
            : '-',
          'post-date': data['post-date'] ? data['post-date'] : data.date,
          to,
          from,
        }
      }) : []
    },
    receiptListTotalElements() {
      return this.receiptList.page ? this.receiptList.page.total_elements : 0
    },
    disableExportData(){
      if(this.filterData.filters.length){
        let dates = this.filterData.filters.find(el => el.key == 'date' || el.key == 'created_at' || el.key == 'post-date')
        let values = dates ? dates.values : []
        return values.length == 0 || this.getDiffDays(values) > 90 ? true : false
      }
      return true
    }
  },
  watch: {
    isModalActive: {
      handler() {
        if(this.receiptDetail.amount.split('.')[1].length == 1){
          this.receiptDetail.amount += '0'
        }
      },
      deep: true
    }
  },
  created() {
    this.filter['start_date'] = this.toLocalDate(
      this.minusDays(new Date(), 30)).toISOString().substr(0, 10);
      this.filter['end_date'] = this.toLocalDate(new Date()).toISOString().substr(0, 10);
  },
  methods: {
    ...mapActions('programMovements', ['apiGetSchemas', 'apiUpdateProgramMovement']),
    ...mapActions('programFilters', ['apiPostMovementsFilters']),
    ...mapMutations(["setDisableNavigation"]),
    ...mapMutations('programMovements', ['setProgramMovements']),
    convertStatus(value) {
      const status = {
        'in-progress': "progress",
        done: "running",
        created: "ok"
      }
      return status[value]
    },
    async changeSort(column) {
      if (this.filterData.pagination.sort_by === column) {
        this.filterData.pagination.sort_order = this.filterData.pagination.sort_order == 'desc'
          ? 'asc' : 'desc'
      } else {
        this.filterData.pagination.sort_by = column
        this.filterData.pagination.sort_order = 'desc'
      }
      this.filterData.filters.sortBy = this.filter.sortBy
      let result = await this.apiPostMovementsFilters(this.filterData)
      this.setProgramMovements(result.data)
    },
    async getReceiptMovements() {
      let result = {}
      this.setPaginationToFilter()
      loader.startProgressLoadPage()

      result = await this.apiPostMovementsFilters(this.filterData)
      this.setProgramMovements(result.data)

      loader.stopProgressLoadPage()
    },
    updatePagination(pagination) {
      const reducePaginationAttributes = {
        page: pagination.page,
        itemsPerPage: pagination.itemsPerPage
      }
      this.pagination = reducePaginationAttributes
      Object.assign(this.filterData.pagination, { page: pagination.page, size: pagination.itemsPerPage })
      this.getReceiptMovements()
    },
    updateModel(event) {
      this.selected = event;
    },
    toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    },
    minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - days))
    },
    setPaginationToFilter() {
      this.filter.page = this.pagination.page;
      this.filter.size = this.pagination.itemsPerPage;
    },
    filterReceipts(filters) {
      this.pagination.page = 1
      Object.assign(this.filterData, filters)
      this.getReceiptMovements()
    },
    updateModalActive(value) {
      this.isModalActive = value
    },
    onCancelForm() {
      this.isModalActive = false
      this.forceResetInputValue(this, 'amount')
    },
    async openReceiptDetail(receipt) {
      await this.apiGetSchemas({ scope: 'receipts' })
      Object.assign(this.receiptDetail, {...receipt, noFocus: true})
      this.isModalActive = true
      this.receiptPostings = receipt
    },
  },
  beforeRouteLeave(to, from, next){
    this.setProgramMovements([])
    next()
  }
}
</script>

<style lang="scss">
.receipt-list__header__actions {
  display: flex;
  align-items: center;
}

.receipt-list .base-data-table {
  .check {
    width: 40px;
  }

  .status {
    width: 177px;
  }

  .type {
    width: 140px;
  }


  .date {
    width: 110px;
  }

  .buyer, .seller {
    width: 300px;
  }
}

.receipt-list {
  .base-data-table th {
    font-weight: 600 !important;
  }

  .v-data-footer {
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
  }

  .receipt-list__label-cart {
    border-radius: 50px 0 0 50px;
    font-size: 12px !important;
    cursor: pointer;
    min-width: 187px;
    height: 30px;
    padding: 3px 23px 3px 5px;
    margin: 12px -25px 0 0;
    transition: min-width .4s;
  }

  .receipt-list__item--status {
    color: var(--custom-theme-black-054) !important;
    display: inline-block;
    font-size: 10px !important;
    height: 24px !important;
    min-width: 120px;
    margin: 4px !important;
    text-align: center;
  }

  .animate-label-cart {
    min-width: 197px !important;
  }
}
</style>
