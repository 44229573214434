<template>
  <BaseContainer>
    <div class="card-data-table">
      <BaseDataTable
        v-bind="{ ...$attrs, ...$props }"
        v-on="$listeners"
      >
        <template
          v-slot:inject-header="{ headers }"
        >
          <tr class="card-data-table__table__header-tr elevation-2 rounded">
            <slot
              name="inject-header"
              :headers="headers.props"
            />
          </tr>
        </template>

        <template v-slot:no-result-text-message>
          <p class="font-weight-bold mb-0 body-2">
            Nenhum item para exibir.
          </p>
          <p class="font-weight-regular body-2">
            Você pode refinar a busca na opção "Filtrar" ou
            navegar entre as páginas.
          </p>
        </template>

        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.index"
            class="card-data-table__table__body-tr elevation-2 rounded"
            :class="{'card-data-table_tr--is-expanded': props.isExpanded, 'app-apply-blur' : isAdminEditing}"
          >
            <td style="display: block;">
              <div class="card-data-table__info-overview">
                <template
                  v-for="item, name, index in filterItems(props.item)"
                >
                  <div
                    v-if="name !== 'id' && name !== 'actions' && name !== 'expand' && name !== 'cancel_reason'"
                    :key="index"
                    :class="['card-data-table__info-overview__item', transformToKebabCase(name, 'regExp:snakeCase') ]"
                  >
                    <p
                      :class="['card-data-table__info-overview__text', `${name}--text`]"
                      class="text-truncate"
                    >
                      <template v-if="isObject(item) && name !== 'status'">
                        <v-tooltip
                          v-if="(item.value.length / 2) * item.fontSize > item.width"
                          bottom
                        >
                          <template
                            v-slot:activator="{ on }"
                          >
                            <span
                              v-on="on"
                            >
                              {{ item.value }}
                            </span>
                          </template>
                          <span>{{ item.value }}</span>
                        </v-tooltip>
                        <span v-else>{{ item.value }}</span>
                      </template>
                      <span v-else>
                        <span v-if="name === 'status'">
                          <v-chip
                            class="platform-trades__item--status font-weight-medium ma-0"
                            :color="item.statusColor"
                          >
                            {{ item.name }}
                          </v-chip>
                        </span>
                        <span v-else>
                          <v-tooltip
                            v-if="item.length > 12"
                            bottom
                          >
                            <template
                              v-slot:activator="{ on }"
                            >
                              <span
                                v-on="on"
                              >
                                {{ item }}
                              </span>
                            </template>
                            <span>{{ item }}</span>
                          </v-tooltip>
                          <span v-else>{{ item }}</span>
                        </span>
                      </span>
                    </p>
                  </div>
                  <div
                    v-else-if="name === 'actions' && isArray(item)"
                    :key="index"
                    :class="[item.length > 0 ? 'card-data-table__info-overview__item' + transformToKebabCase(name, 'regExp:snakeCase') : '']"
                  >
                    <p :class="['card-data-table__info-overview__text']">
                      <template>
                        <BaseButton
                          v-for="action in item"
                          :key="action.text"
                          :ref="action.text"
                          :type="action.type"
                          :has-icon="false"
                          :is-disabled="action.disabled"
                          color="blueff"
                          button-class="app-button__link-text app-button--hide-ripple app-button--no-hover mx-0 px-0"
                          @click="(ev) => dispatchEvent(ev, { type: action.dispatchEvent, value: props.item })"
                        >
                          {{ action.text }}
                        </BaseButton>
                      </template>
                    </p>
                  </div>
                  <div
                    v-else-if="name === 'id'"
                    :key="index"
                    :class="['card-data-table__info-overview__item', transformToKebabCase(name, 'regExp:snakeCase')]"
                  >
                    <p :class="['card-data-table__info-overview__text']">
                      <template>
                        <BaseButton
                          id="expand-table-trade"
                          :ref="props.item.id"
                          type="text"
                          :has-icon="true"
                          color="#264674"
                          @click="(ev) => handleClickExpand(ev, props)"
                        >
                          <v-icon v-if="props.isExpanded">
                            expand_less
                          </v-icon>
                          <v-icon v-else>
                            expand_more
                          </v-icon>
                        </BaseButton>
                      </template>
                    </p>
                  </div>
                </template>
              </div>
            </td>
          </tr>
        </template>
        <template
          v-slot:inject-expand="props"
        >
          <div
            class="card-data-table--expanded"
            style="background-color: #F4F5F8"
          >
            <slot
              name="inject-expand"
              :items="props"
            />
          </div>
        </template>
      </BaseDataTable>
    </div>
  </BaseContainer>
</template>

<script>
import BaseDataTable from './BaseDataTable.vue'
import BaseButton from './BaseButton.vue'

import { transformToKebabCase } from '../utils'

export default {
  name: "CardDataTable",
  components: { BaseDataTable, BaseButton },
  props: {
    ...BaseDataTable.props
  },
  methods: {
    transformToKebabCase,
    filterItems(items) {
      const regExp = /layer_\w*/g
      const itemsKey = Object.keys(items)

      const filterItems = Object.assign({}, items)

      let match

      while ((match = regExp.exec(itemsKey.join(','))) !== null) {
        this.$delete(filterItems, match);
      }

      const { tab, ...data } = filterItems
      return data
    },
    isObject(value) {
      return !Array.isArray(value) && typeof value == 'object'
    },
    isArray(value) {
      return Array.isArray(value)
    },
    dispatchEvent(ev, dispatchEvent) {
      this.$emit(dispatchEvent.type,
        Object.assign(ev, { dispatchEvent }))
    },
    handleClickExpand(ev, props, forcedDispacth=false) {
      const { item } = props

      if(forcedDispacth) {
        if(item.expand === undefined) {
          props.expand(!props.isExpanded)
        } else {
          props.expand(item.expand)
        }
      } else {
        props.expand(!props.isExpanded)
      }
    },
    getInternalRefs(name) {
      return this.$refs[name]
    }
  }
}
</script>

<style scoped>
  .card-data-table tbody tr:not(.card-data-table__table__body-tr) {
    background: transparent;
  }

  .card-data-table tbody tr:not(.card-data-table__table__body-tr) td {
    width: 100%;
  }

  .card-data-table .v-table__overflow + div,
  .card-data-table .card-data-table--expanded {
    margin: -12px 3px 3px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
    width: calc(100% - 6px);
  }

  .card-data-table  .card-data-table--expanded {
    margin-bottom: 24px;
  }

  .list-no-itens--container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .list-no-itens--container div {
    display: flex;
    align-items: center;
    flex-flow: column;
  }

  .list-no-itens--text {
    color: var(--custom-theme-black-054) !important;
    font-weight: bold;
  }

  .card-data-table__header__actions {
    display: flex;
    align-items: center;
  }

  .card-data-table table {
    background-color: transparent !important;
    display: flex;
    flex-flow: column wrap;
  }

  .card-data-table thead,
  .card-data-table tbody {
    display: flex;
    flex-flow: row wrap;
    flex: 0 0 100%;
  }

  .card-data-table th {
    font-weight: 400 !important;
  }

  .card-data-table tbody {
    align-content: flex-start;
  }

  .card-data-table tr {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-flow: row wrap;
    flex: 0 0 calc(100% - 6px);
    margin: 3px;
  }

  .card-data-table .base-data-table >>> tr > th {
    padding: 15px 22px !important;
  }
  .card-data-table >>> .card-data-table__table__body-tr.card-data-table_tr--is-expanded + tr:hover {
    background-color: transparent !important;
  }
  .card-data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    border-bottom: none!important;
  }

  .card-data-table >>> .card-data-table__table__body-tr:hover {
    background-color: white !important;
  }

  .card-data-table tr[class*="v-datatable__progress"] {
    margin-top: -8px !important;
  }

  .card-data-table tr[class*="v-datatable__progress"] > * {
    width: 100%;
  }

  .card-data-table thead tr:not(:last-child) {
    border: none !important;
  }

  .card-data-table tbody tr {
    height: auto;
    margin-bottom: 10px;
  }

  .card-data-table tbody tr > * {
    height: auto !important;
  }

  .card-data-table tbody tr:not(:last-child) {
    border: none !important;
  }

  .card-data-table__info-overview {
    align-items: stretch;
    display: flex;
    flex-flow: nowrap;
    padding: 0;
  }

  .card-data-table__info-overview__item {
    align-self: center;
    padding-left: 24px;
  }

  .card-data-table__info-overview__text {
    margin: 0;
  }

  .card-data-table__message {
    border-top: 1px solid #bebebe;
    background-color: #f2f2f2;
    font-size: 10px !important;
    line-height: 1.8;
  }

  .card-data-table__table__header-tr {
    text-align: left;
  }

  .card-data-table .card-data-table__table__body-tr td {
    font-size: 14px !important;
    padding: 0 !important;
    width: 100%;
  }

  .card-data-table thead tr {
    height: 48px !important;
    padding: 0 !important;
    margin-bottom: 10px;
  }

  .card-data-table thead tr th {
    border-bottom: none !important;
  }

  .card-data-table tr > th {
    height: unset !important;
  }

  .card-data-table [data-toogle-text] {
    position: relative;
  }

  .card-data-table [data-toogle-text]:after {
    color: var(--v-blueff-base);
    font-weight: 600;
    content: attr(data-toogle-text);
    position: absolute;
    right: 0;
    top: 0;
    display: block;
  }
</style>
