<template>
  <div class="assets-collection">
    <v-navigation-drawer
      :value="showAssetsCollection"
      app
      right
      clipped
      floating
      class="px-4 mb-12 pt-7"
      color="#f1f1f1"
      width="495"
      mobile-breakpoint="990"
      disable-resize-watcher
    >
      <BaseButton
        :has-icon="true"
        :class="{ 'app-apply-blur' : isAdminEditing }"
        @click="hideAssetsCollection"
      >
        <v-icon>close</v-icon>
      </BaseButton>
      <template v-if="!showAssetsCollectionConfirm">
        <div class="assets-collection__header d-flex justify-end mb-6">
          <div
            class="d-flex text-caption text-right"
            :class="{ 'app-apply-blur' : isAdminEditing }"
          >
            <div>
              <p class="font-weight-bold primary--text">
                Valor da negociação
              </p>
              <p class="font-weight-bold primary--text text-h4">
                {{ programDraftMovementAmount | formatCurrency }}
              </p>
            </div>
            <div class="align-self-end pl-2">
              <v-icon class="assets-collection__header--icon primary--text">
                storefront
              </v-icon>
            </div>
          </div>
        </div>

        <Warning
          v-if="!hasBankAccount"
          class="mx-1"
          icon="warning"
          type="warning"
          theme="light"
        >
          <p :class="{ 'app-apply-blur' : isAdminEditing }">
            <strong>Conta bancária:</strong> Cadastre uma conta
            bancária no perfil da sua organização antes de realizar uma negociação.
            <a
              class=" app-font-semibold text-decoration-underline"
              :href="createLinkToBankAccount"
            >
              Cadastrar conta.
            </a>
          </p>
        </Warning>
        <v-menu
          v-else-if="showAccountList"
          v-model="menu"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              style="font-size: 12px"
              class="d-flex assets-collection__accounts-menu color--text-black-054 pa-1 mx-1"
            >
              <div class="d-flex align-start">
                <v-icon>account_balance_wallet</v-icon>
                <span class="font-weight-bold">Conta Desembolso</span>
              </div>
              <v-spacer />
              <div
                class="d-flex flex-column align-end pr-6"
                :class="{ 'app-apply-blur' : isAdminEditing }"
              >
                <p>Código Banco</p>
                <p
                  class="body-2 font-weight-bold truncate-content-text"
                  style="max-width: 88px;"
                >
                  {{ getBankCodeAndName(accountSelected) }}
                </p>
              </div>
              <div
                class="d-flex flex-column align-end pr-6"
                :class="{ 'app-apply-blur' : isAdminEditing }"
              >
                <p>Agência</p>
                <p class="body-2 font-weight-bold">
                  {{ getAgencyAndDigit(accountSelected) }}
                </p>
              </div>
              <div
                class="d-flex flex-column align-end"
                :class="{ 'app-apply-blur' : isAdminEditing }"
              >
                <p>Conta</p>
                <p class="body-2 font-weight-bold">
                  {{ getNumberAndDigit(accountSelected) }}
                </p>
              </div>
              <BaseButton
                type="text"
                context-button="program"
                button-class="my-0"
                :has-icon="true"
                v-bind="attrs"
                :class="{ 'app-apply-blur' : isAdminEditing }"
                v-on="on"
              >
                <v-icon color="primary">
                  expand_more
                </v-icon>
              </BaseButton>
            </div>
          </template>
          <v-list>
            <template v-for="bankInfo in programBankAccounts">
              <div
                :key="bankInfo.id"
                style="font-size:12px"
                class="d-flex assets-collection__accounts-menu--select color--text-black-054 justify-end px-4 py-1"
                @click="selectAccount(bankInfo)"
              >
                <BaseDataBank
                  description=""
                  :bank-value="getBankCodeAndName(bankInfo)"
                  :agency-value="getAgencyAndDigit(bankInfo)"
                  :account-value="getNumberAndDigit(bankInfo)"
                  style="border: none; width: 100%; padding: 0px!important"
                />
              </div>
            </template>
          </v-list>
        </v-menu>

        <div class="assets-collection__actions--container mt-6 mx-1">
          <div class="assets-collection__actions d-flex align-center justify-space-between">
            <p class="font-weight-bold text-body-2 primary--text pl-6">
              {{ showTotalOfAssetsSelected }} selecionados
            </p>
            <div :class="{ 'app-apply-blur' : isAdminEditing }">
              <BaseButton
                type="text"
                class="primary--text app-font-semibold"
                @click="deleteDraftMovement"
              >
                Excluir
              </BaseButton>
              <BaseButton
                color="primary"
                class="white--text app-font-semibold"
                :is-disabled="isButtonDisabled"
                @click="() => showAssetsCollectionConfirm = true"
              >
                Avançar
              </BaseButton>
            </div>
          </div>
          <BaseDataTable
            :headers="headers"
            :hide-headers="true"
            :is-admin-editing="isAdminEditing"
            :items="dataDraftMovementsChildren"
            class="color--text-black-054"
          >
            <template v-slot:inject-header="{headers}">
              <tr>
                <template
                  v-for="(header, index) in headers.props.headers"
                >
                  <th
                    v-if="header.value != 'admin-config'"
                    :key="index"
                    style="white-space: nowrap;"
                  >
                    {{ translateRoleType(header.text) }}
                  </th>
                  <th
                    v-if="header.value == 'admin-config'"
                    :key="index"
                    class="text-right"
                  >
                    <BaseButton
                      v-if="isAdminEditing"
                      type="text"
                      :has-icon="true"
                    >
                      <v-icon>settings</v-icon>
                    </BaseButton>
                  </th>
                </template>
              </tr>
            </template>
            <template v-slot:inject-items="scopedItems">
              <tr
                v-for="props in scopedItems"
                :key="props.item.id"
              >
                <template
                  v-for="header, index in headers"
                >
                  <td
                    v-if="header.value != 'admin-config'"
                    :key="index"
                    class="text-left"
                  >
                    <div v-if="header.value.includes('date') || header.value === 'created_at'">
                      {{ props.item[header.value] | getDate }}
                    </div>
                    <div v-else-if="header.value.includes('amount')">
                      {{ props.item[header.value] | formatCurrency }}
                    </div>
                    <div v-else>
                      {{ props.item[header.value] || '-' }}
                    </div>
                  </td>
                </template>
                <td class="text-right">
                  <BaseButton
                    type="text"
                    :has-icon="true"
                    @click="$emit('deleteDraftMovementChild', props.item.score)"
                  >
                    <v-icon class="primary--text">
                      delete
                    </v-icon>
                  </BaseButton>
                </td>
              </tr>
            </template>
          </BaseDataTable>
        </div>
      </template>
      <AssetsCollectionConfirm
        v-else
        :total-of-assets="programDraftMovementsChildren.length"
        :amount="programDraftMovementAmount"
        :bank-account="accountSelected"
        @back-page="backPage"
        @close-assets-collection="hideAssetsCollection"
        @updateAssetList="updateAssetList"
        @showError="showError"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>

import store from '../../store'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

import BaseDataTable from '../BaseDataTable.vue'
import AssetsCollectionConfirm from './AssetsCollectionConfirm'
import BaseDataBank from '../BaseDataBank.vue'
import UtilsBankAccount from '../../mixins/UtilsBankAccount'
import Warning from '../Program/Warning.vue'
import ProgramRoles from "@/mixins/ProgramRoles";

export default {
  components: {BaseDataTable, AssetsCollectionConfirm, BaseDataBank, Warning},
  mixins: [UtilsBankAccount, ProgramRoles],
  props: {
    showAssetsCollection: {
      type: Boolean,
      required: true
    },
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showAssetsCollectionConfirm: false,
      menu: false,
      headers: [
        {
          text: 'Número',
          value: 'number',
          class: 'numero',
          sortable: false
        },
        {
          text: 'Vencimento',
          value: 'post-date',
          class: 'vencimento',
          sortable: false
        },
        {
          text: 'Valor',
          value: 'amount',
          class: 'valor',
          sortable: false
        },
        {
          text: '',
          value: 'admin-config',
          class: '',
          sortable: false
        }
      ],
      accountSelected: null,
      showAccountList: false
    }
  },
  computed: {
    ...mapState({
      accountId: state => state.auth.keycloak.tokenParsed['account-id'],
      programDraftMovementAmount: state => state.programDraftMovements.programDraftMovementAmount,
      programDraftMovementId: state => state.programDraftMovements.programDraftMovementId,
      programDraftMovementsChildren: state => state.programDraftMovements.programDraftMovementsChildren
    }),
    ...mapState('programMovements', ['programBankAccounts']),
    dataDraftMovementsChildren() {
      return this.programDraftMovementsChildren ? this.programDraftMovementsChildren.map(element => {
        const {type, id, score, data} = element
        const asset = {
          type,
          score,
          ...data
        }
        return asset
      }) : []
    },
    showTotalOfAssetsSelected() {
      return this.programDraftMovementsChildren.length;
    },
    hasBankAccount() {
      return this.programBankAccounts.length > 0
    },
    isButtonDisabled() {
      return !this.hasBankAccount || this.showTotalOfAssetsSelected == 0
    },
    roleOrganizationId() {
      return this.getRoleOrganizationId()
    },
    createLinkToBankAccount() {
      const url = window.location.hostname
      let index = url.indexOf('.')
      const newUrl = url.slice(index + 1)
      return `https://${newUrl}/#/platform-organization/${this.roleOrganizationId}/bank-account`
    }
  },
  methods: {
    ...mapActions('programMovements', ['apiGetProgramBankAccounts']),
    ...mapActions('programDraftMovements',
      ['apiGetDraftMovementsChildren', 'apiDeleteDraftMovement']),
    ...mapMutations('programDraftMovements', ['setDraftMovementsChildren']),
    hideAssetsCollection() {
      this.$emit("hideAssetsCollection");
      this.showAssetsCollectionConfirm = false
    },
    updateAssetList(){
      this.$emit('updateAssetList')
    },
    showError(value){
      this.$emit("showError", value)
    },
    async deleteDraftMovement() {
      await this.apiDeleteDraftMovement({type: 'trade'})
      this.setDraftMovementsChildren([])
      this.$emit("hideAssetsCollection");
      this.showAssetsCollectionConfirm = false
    },
    backPage() {
      this.showAssetsCollectionConfirm = false
    },
    selectAccount(account) {
      this.accountSelected = account;
    },
    setPrimaryAccount() {
      this.accountSelected = this.programBankAccounts.filter(
        account => account.primary === true)[0] || this.programBankAccounts[0]
    },
    async getBankAccounts() {
      try {
        await this.apiGetProgramBankAccounts();
        this.setPrimaryAccount()
      } catch (error) {
        throw error;
      }
    }
  },
  watch: {
    accountSelected() {
      this.showAccountList = this.accountSelected ? true : false
    }
  },
  mounted() {
    this.getBankAccounts()
    if (this.programDraftMovementId) {
      this.apiGetDraftMovementsChildren({type: 'trade', id: this.programDraftMovementId})
    }
  },

}
</script>

<style scoped>
.assets-collection__header--icon {
  font-size: 40px
}

.assets-collection__actions {
  background-color: #F4F5F8;
  padding: 10px 10px 10px;
}

.assets-collection__accounts-menu {
  border: 1px solid rgba(38, 70, 116, 0.102);
  border-radius: 2px;
}

.assets-collection__accounts-menu > div .v-icon {
  font-size: 1.125rem;
}

.assets-collection__accounts-menu--select {
  background: #fff;
}

.assets-collection__accounts-menu--select:hover {
  background: #e9ecf1;
}

/* TABLE */
.theme--light.v-data-table >>> .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.12) !important;
}

.v-data-table >>> th {
  font-weight: 600;
}

.assets-collection__actions--container {
  box-shadow: 0px 1px 3px #00000061;
}
</style>
