import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState({
      programRoles: (state) => {
        return state.platformProgram.programRoles
      },
      programRoleTypes: (state) => {
        return state.platformProgram.programRoleTypes
      },
      activeOrganization: (state) => {
        return state.platformProgram.activeOrganization
      }
    })
  },
  methods: {
    userHasProgramRoles(roles) {
      if (this.programRoles.length > 0) {
        return roles.find(role => this.hasProgramRole(role)) ? true : false
      }
    },
    hasProgramRole(role){
      const { active_role } = this.activeOrganization
      if (this.programRoles.length > 1) {
        return role === active_role ? true : false
      } else {
        return this.programRoles.length > 0 ?
            role === this.programRoles[0].role ? true : false
          : false
      }
    },
    isTradingPartner() {
      return this.hasProgramRole('trading-partner')
    },
    isSeller() {
      return this.hasProgramRole('seller')
    },
    isBuyer() {
      return this.hasProgramRole('buyer')
    },
    isOwner() {
      return this.hasProgramRole('owner')
    },
    isReviewer() {
      return this.hasProgramRole('reviewer')
    },
    getProgramRoleByKey(role) {
      let roles =  this.programRoleTypes.filter(r => r.type == role)
      if(roles.length > 0) {
        return roles[0];
      }
      //para evitar null pointer em programas que não tem role associada
      return {description: role, type: role}
    },
    getRoleTypes() {
      let roleTypes = [{text: 'Todos', value: ''}]

      this.programRoleTypes.map(el => {
        return {
          text: el.description,
          value: el.type
        }
      })
      .map(role => roleTypes.push(role))

      return roleTypes
    },
    getRoleOrganizationId() {
      return this.activeOrganization.active_organization_id
    },
    getRoleOrganizations() {
      const { active_role } = this.activeOrganization
      if(this.programRoles.length > 0 && active_role !== 'owner') {
        let roleOrganizations = this.programRoles.filter(elem => elem.role === active_role)[0].enrolled_organizations
        return roleOrganizations.map(organization => {
          return {
            ...organization,
            nameAndTaxnumber: `${organization.name} - ${organization.tax_number}`
          }
        })
      }
      return []
    },
    getActiveOrganization(){
      const { active_organization_id } = this.activeOrganization
      return this.getRoleOrganizations().filter(elem => elem.id === active_organization_id)[0]
    },
    translateRoles(role){
      const roles = {
        "trading-partner": "Agente Financeiro",
        "seller": "Fornecedor",
        "buyer": "Sacado",
        "owner": "Gestor",
        "reviewer": "Revisor"
      }
      return roles[role]
    },
    translateRoleType(role){
      let programRole = this.programRoleTypes.find(r => r.type == role)
      if(programRole) {
        return programRole.description;
      } else {
        return this.translateRoles(role) || role
      }
    }
  }
};
