import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    programDraftMovementId: null,
    programDraftMovementAmount: null,
    programDraftMovementsChildren: []
  },
  mutations: {
    setDraftMovementsId(state, payload) {
      state.programDraftMovementId = payload
    },
    setDraftMovementsAmount(state, payload) {
      state.programDraftMovementAmount = payload
    },
    setDraftMovementsChildren(state, payload) {
      state.programDraftMovementsChildren = payload
    }
  },
  actions: {
    async apiGetDraftMovements({commit}, payload) {
      const result = await api.get(`/draft_movements/${payload}`, {
        validateStatus: function (status) {
          return status == 200 || status == 204;
        }
      });
      switch (result.status) {
        case 200:
          commit("setDraftMovementsId", result.data.data.id)
          commit("setDraftMovementsAmount", result.data.data.amount)
        break
        case 204:
          commit("setDraftMovementsId", null)
          commit("setDraftMovementsAmount", null)
        break
      }

      return result
    },
    async apiGetDraftMovementsChildren({commit}, payload) {
      const {type, id} = payload
      const result = await api.get(`/draft_movements/${type}/${id}/children`)
      commit("setDraftMovementsChildren", result.data.content)
      return result
    },
    async apiUpdateDraftMovement({commit}, payload) {
      const {type, data} = payload
      let endpoint = `/draft_movements/${type}`
      await api.put(endpoint, data)
    },
    async apiCreateDraftMovement({commit}, payload) {
      const {type, data} = payload

      const result = await api.post(`/draft_movements/${type}`, data)
      return result
    },
    async apiDeleteDraftMovement({commit}, payload) {
      const {type} = payload
      const result = await api.delete(`/draft_movements/${type}`)
      return result
    },
    async apiSendDraftMovementChildren({commit}, payload) {
      const {type, id, data} = payload
      const result = await api.put(`/draft_movements/${type}/${id}/children`,
        data)
      return result
    },
    async apiDeleteDraftMovementChild({commit}, payload) {
      const {type, idDraft, idChild, data} = payload
      const result = await api.delete(`/draft_movements/${type}/${idDraft}/children/${idChild}`,
        data)
      return result
    }
  }
}
