<template>
  <div class="platform-trades">
    <h2>{{ headerTitle }}</h2>
    <p class="mb-4">
      {{ headerTitleDescription }}
    </p>
    <CardDataTable
      background-color="transparent"
      :hide-headers="true"
      :headers="headers"
      :items="tableList"
      :options="{ page: 1, itemsPerPage: 25 }"
      :total-items="1"
    >
      <template v-slot:inject-header="{headers}">
        <th
          v-for="(header, index) in headers.headers"
          :key="index"
          style="white-space: nowrap;"
          :class="header.class"
          class="app-apply-blur"
        >
          {{ header.text }}
        </th>
      </template>
      <template v-slot:inject-expand="scoped">
        <v-divider v-if="scoped" />
        <BaseActionGroup
          description="Ações para essa negociação"
          :action-list="[]"
          :current-object="{}"
          class="app-apply-blur"
          @onClickAction="()=>{}"
        />
        <v-divider />
        <div class="platform-trades__payments d-flex justify-end flex-wrap app-apply-blur">
          <BaseDataBank
            v-for="payment, index in [{}]"
            :key="index"
            description="Conta Bancária"
            agency-value="0000"
            account-value="0000"
            bank-value="000"
            style="width: 396px; padding: 8px 24px !important;"
          />
        </div>
        <v-divider />
        <v-tabs
          v-model="tabItem"
          align-with-title
          class="trade-tabs"
        >
          <v-tabs-slider color="primary" />
          <v-tab 
            class="tab"
            :class="{'app-apply-blur': tabItem != 0 }"
          >
            <span class="tab--text">ATIVO(S)</span>
          </v-tab>
          <v-tab 
            class="tab"
            :class="{'app-apply-blur': tabItem != 1 }"
          >
            <span class="tab--text">PROPOSTA(S)</span>
          </v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items
          v-model="tabItem"
          style="background-color: transparent;"
        >
          <div 
            v-for="item, indexTab in ['asset', 'offer']" 
            :key="item"
          >
            <v-tab-item>
              <BaseDataTable
                v-if="indexTab == tabItem"
                background-color="#F4F5F8"
                :hide-headers="true"
                :headers="tableHeaders"
                :items="tableHeaders"
                style="min-height: 480px;"
              >
                <template v-slot:inject-header="{headers}">
                  <tr>
                    <template
                      v-for="(header, index) in headers.props.headers"
                    >
                      <th
                        v-if="header.value != 'admin-config'"
                        :key="index"
                        style="white-space: nowrap;padding: 15px !important;"
                      >
                        {{ translateRoleType(header.text) }}
                      </th>
                      <th
                        v-if="header.value == 'admin-config'"
                        :key="index"
                        class="text-right"
                      >
                        <MenuConfigurationSchemaField
                          menu-title="Configurar tabela"
                          name="configurationTableOfferOrAsset"
                          menu-align="left"
                          :config-list="programMovementDynamicAssetFields"
                          :form-field-list="['name','hidden', 'datasource']"
                          :scope-schema="scopeSchema"
                          @removeItemSchema="removeItemSchema"
                          @addOrEditItemSchema="addOrEditItemSchema"
                          @changeOrderItemSchema="changeOrderItemSchema"
                        />
                      </th>
                    </template>
                  </tr>
                </template>

                <template
                  v-slot:inject-items="scopedItems"
                >
                  <tr
                    v-for="props in scopedItems"
                    :key="props.index"
                    class="app-apply-blur"
                  >
                    <template
                      v-for="header, index in tableHeaders"
                    >
                      <td
                        v-if="header.value !== 'admin-config'"
                        :key="index"
                        class="text-left"
                      >
                        <div>
                          {{ header.text }}
                        </div>
                      </td>
                      <td
                        v-if="header.value === 'admin-config'"
                        :key="index"
                        class="text-right"
                      />
                    </template>
                  </tr>
                </template>
              </BaseDataTable>
            </v-tab-item>
          </div>
        </v-tabs-items>
      </template>
    </CardDataTable>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import permissions from "@/mixins/permissions";
import ProgramRoles from '../../mixins/ProgramRoles'
import BaseDataTable from '../BaseDataTable'
import MenuConfigurationSchemaField from '../Platform/MenuConfigurationSchemaField'
import BaseActionGroup from '../../components/BaseActionGroup'
import BaseDataBank from '../BaseDataBank'
import CardDataTable from '../CardDataTable'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'

export default {
  components: { BaseDataTable, MenuConfigurationSchemaField, BaseActionGroup, BaseDataBank, CardDataTable },
  mixins: [ permissions, ProgramRoles, BaseSnackbarConfiguration ],
  props: {
    headerTitle: {
      type: String,
      required: true
    },
    headerTitleDescription: {
      type: String,
      required: true
    },
    scopeSchema: {
      type: String,
      required: false,
      default: ''
    },
    tabIndex: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    tableList: [{
      status: {
        name: 'Disponível',
        statusColor: 'done',
        category: 'created',
      },
      number: (() => {
        return {
          value: '000',
          toogle: true,
          fontSize: 14,
          width: 120
        }
      })(),
      trading_partner: (() => {
        return {
          value: 'Agente Financeiro',
          toogle: true,
          fontSize: 14,
          width: 150
        }
      })(),
      seller: (() => {
        return {
          value: 'Fornecedor',
          toogle: true,
          fontSize: 14,
          width: 150
        }
      })(),
      parties: (() => {
        return {
          value: 'Participante',
          toogle: true,
          fontSize: 14,
          width: 150
        }
      })(),
      date: '01/01/2024',
      amount: 'R$1000,00',
      actions: null,
      id: 'x',
    }],
    headers: [
      {
        text: 'Status',
        value: 'status.category.keyword',
        class: 'status',
        sortable: true
      },
      {
        text: 'Numero',
        value: 'number.keyword',
        class: 'number',
        sortable: true
      },
      {
        text: 'Agente Financeiro',
        value: 'to.name.keyword',
        class: 'trading-partner',
        sortable: true,
        permissions: ["owner", "buyer", "seller"]
      },
      {
        text: 'Fornecedor',
        value: 'from.name.keyword',
        class: 'seller',
        sortable: true
      },
      {
        text: 'Participantes',
        value: 'parties',
        class: 'parties',
        sortable: true
      },
      {
        text: 'Data',
        value: 'date',
        class: 'date',
        sortable: true
      },
      {
        text: 'Valor',
        value: 'amount',
        class: 'amount',
        sortable: true
      },
      {
        text: '',
        value: 'id',
        class: 'id',
        sortable: false
      }
    ],
    tableHeaders: [],
    tabItem: null,
  }),
  computed:{
    ...mapState({
      programMovementDynamicAssetFields: state => state.programMovements.programMovementDynamicAssetFields
    })
  },
  watch: {
    programMovementDynamicAssetFields: {
      handler(val) {
        this.parseHeaders()
      },
      deep: true,
    }
  },
  async created() {
    await this.apiGetSchemas({ scope: this.scopeSchema })
    this.parseHeaders()
    this.tabItem = this.tabIndex
  },
  mounted(){
    document.getElementById('expand-table-trade').click()
  },
  methods: {
    ...mapActions('programMovements', ['apiGetSchemas']),
    ...mapActions(['apiGetSchemasFields', 'apiUpdateSchemaField', 'apiCreateSchemaField']),
    async removeItemSchema({ field, role, schemaName, programName }) {
      const payloadList = this.programMovementDynamicAssetFields.filter(elem => elem.key !== field.key)
      try {
        await this.apiUpdateSchemaField({
          name: schemaName,
          scope: this.scopeSchema,
          fields: payloadList,
          role: role,
          program: programName
        })
        this.showFeedback({
          message: 'Campo removido com sucesso!',
          color: 'success'
        })
      } catch (error) {
        this.showFeedback({
          message: 'Erro ao remover campo.',
          color: 'error'
        })
      } finally {
        await this.apiGetSchemasFields()
        await this.apiGetSchemas({ scope: this.scopeSchema, role })
        this.parseHeaders()
      }
    },
    addOrEditItemSchema({key, role, schemaName, programName, data }){
      const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: [],
        role,
        program: programName
      }

      if(this.programMovementDynamicAssetFields.length) {
        const payloadList = this.programMovementDynamicAssetFields.filter(field => field.key !== key)
        payloadList.push(data)

        Object.assign(payload, {
          ...payload,
          fields: payloadList
        })
        this.updateSchema(payload)
      } else {
        Object.assign(payload, {
          ...payload,
          fields: [data]
        })
        this.createSchema(payload)
      }
    },
    async createSchema(payload) {
      try {
        await this.apiCreateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao criar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: this.scopeSchema, role: payload.role })
        this.parseHeaders()
      }
    },
    async updateSchema(payload){
      try {
        await this.apiUpdateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao atualizar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: this.scopeSchema, role: payload.role })
        this.parseHeaders()
      }
    },
    changeOrderItemSchema({ role, schemaName, programName, data }){
      const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: data,
        role,
        program: programName
      }
      this.updateSchema(payload)
    },
    parseHeaders(){
      let tableHeaderFields = this.programMovementDynamicAssetFields || []
      let fields = tableHeaderFields.map(header => {
        return {
          hidden: header.hidden,
          text: header.name,
          value: header.key,
          class: header.key
        }
      })
      fields.push({
        hidden: false,
        text: '',
        value: 'admin-config',
        class: ''
      })
      this.tableHeaders = [...fields]
    },
  }
}
</script>
<style scoped>
  .schema-modal--tabs .v-tab {
    min-width: 90px !important;
    height: unset!important;
    max-height: unset!important;
    display: flex;
    text-align: center;
    padding: 0 16px;
    text-transform: uppercase;
  }
  .schema-modal--tabs .v-tab--active {
    color: var(--primary)!important;
    background: unset;
    font-weight: 600;
  }
  .schema-modal--tabs .v-item-group.v-tabs-items {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
</style>
