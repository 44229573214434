
<template>
  <v-navigation-drawer
    v-model="localDrawerNavigation"
    app
    clipped
    floating
    fixed
    mobile-breakpoint="990"
    :width="sizeNavigation"
    :style="{ 'background-color': navigationBg }"
    class="app-navigation"
  >
    <SelectRole v-if="showSelectRoles" />
    <BaseNavigationMenuList
      :menu-list="menuWithPermission(navigationPrimary)"
      :navigation-mini="navigationMini"
    />

    <template v-slot:append>
      <BaseNavigationMenuList
        :menu-list="menuWithPermission(navigationExtra)"
        :navigation-mini="navigationMini"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
import ProgramRoles from "@/mixins/ProgramRoles";
import BaseNavigationMenuList from './BaseNavigationMenuList'
import SelectRole from './Program/Roles/SelectRole'
import permissions from "@/mixins/permissions";
import {mapState} from 'vuex'

export default {
  components: { BaseNavigationMenuList, SelectRole },
  mixins: [ProgramRoles, permissions],
  props: {
    value: Boolean,
    navigationContentItens: {
      type: Array,
      required: true
    },
    navigationBg: {
      type: String,
      required: true
    },
    navigationMini: {
      type: Boolean,
      required: true
    },
    navigationContext: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      localDrawerNavigation: (() => {
        return this.value
      })(),
      sizeNavigation: (() => {
        return this.navigationMini === true ? '72' : '256'
      })(),
      navigationExtra: [],
      navigationPrimary: []
    }
  },
  computed: {
    ...mapState({
      programRoles: state => state.platformProgram.programRoles
    }),
     showSelectRoles() {
      return this.navigationContext === 'program' && this.programRoles.length > 1  ? true : false
    }
  },
  watch: {
    value() {
      this.localDrawerNavigation = this.value
    },
    localDrawerNavigation() {
      this.$emit('input', this.localDrawerNavigation)
    },
    navigationMini() {
      this.sizeNavigation = this.navigationMini === true ? '72' : '256'
    },
    navigationContentItens(){
      this.splitNavigationMenus()
    }
  },
  created(){
    this.splitNavigationMenus()
  },
  methods: {
    clearMenus(){
      this.navigationExtra = []
      this.navigationPrimary = []
    },
    splitNavigationMenus(){
      this.clearMenus()
      if(this.navigationContext === 'program') {

        this.navigationContentItens.map(menu => {

          if(menu.footerMenu){
            this.navigationExtra.push(menu)
          } else {
            this.navigationPrimary.push(menu)
          }

        })
      } else {
        this.clearMenus()
        this.navigationPrimary = this.navigationContentItens
      }
    },
    menuWithPermission(menuList){
      return menuList.map(menu => {
        let menuItensWithPermission = []

        for (let i = 0; i < menu.itens.length; i++) {
          let menuItem = menu.itens[i]
          if(menuItem.permissions){

            if(this.userHasProgramRoles(menuItem.permissions) || this.userHasResourceRole(menuItem.permissions) || this.userHasGroup(menuItem.permissions)){
              menuItensWithPermission.push(menuItem)
            }

          } else {
            menuItensWithPermission.push(menuItem)
          }
        }

        return {
          name: menu.name,
          itens: menuItensWithPermission
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
