<template>
  <BaseCard
    :has-main-container="true"
    :is-header-title-description="true"
  >
    <template v-slot:header-title>
      <div>{{ headerTitle }}</div>
    </template>
    <template v-slot:header-title-description>
      <div class="pb-4">
        {{ headerTitleDescription }}
      </div>
    </template>
    <div style="min-height: 450px;">
      <v-form
        :ref="formName"
        v-model="formIsValid"
      >
        <div
          class="d-flex justify-end mb-4"
          :style="{ 'margin-top': contextActions === 'create' ? '-40px' : null }"
        >
          <BaseButton
            v-if="contextActions === 'edit' && hasPermissionToEditAsset() && !isEditingAsset && !isAdminEditing"
            type="text"
            color="blueff"
            class="app-button__link-text app-button--hide-ripple app-button--no-hover"
            @click="editAsset"
          >
            Editar
          </BaseButton>
          <BaseButton
            v-if="(contextActions === 'read' || contextActions === 'edit') && newAsset.origin && !isAdminEditing"
            type="text"
            color="blueff"
            class="app-button__link-text app-button--hide-ripple app-button--no-hover ml-0 pl-0"
            @click="downloadFile({uuid: newAsset.origin, fileName: newAsset.number})"
          >
            Baixar
          </BaseButton>
          <BaseButton
            v-if="(contextActions === 'read') && !isAdminEditing && registerAsset"
            type="text"
            color="blueff"
            class="app-button__link-text app-button--hide-ripple app-button--no-hover ml-0 pl-0"
            @click="moveToLogsAndInstallments"
          >
            Logs e parcelas
          </BaseButton>
          <tr
            v-if="userIsAdmin"
            :class="{ 'header-table' : contextActions === 'create' }"
          >
            <td v-if="contextActions === 'create'">
              <v-icon class="pr-2">
                description
              </v-icon>
              <span class="app-font-semibold">
                Dados do Ativo
              </span>
            </td>
            <td>
              <MenuConfigurationSchemaField
                v-if="isAdminEditing"
                menu-title="Configurar"
                :menu-align="contextActions === 'create' ? 'right' : 'left'"
                name="configurationFormAsset"
                :config-list="programMovementDynamicAssetFields"
                :form-field-list="['name', 'input_type', 'type', 'required', 'read_only', 'datasource', 'help_text', 'validation', 'hidden']"
                :scope-schema="scopeSchema"
                @removeItemSchema="removeItemSchema"
                @addOrEditItemSchema="addOrEditItemSchema"
                @changeOrderItemSchema="changeOrderItemSchema"
              />
            </td>
          </tr>
        </div>
        <template v-if="!disableActionButtons">
          <div
            v-for="field in programMovementDynamicAssetFields"
            :key="field.id"
            class="px-6"
          >
            <template v-if="field.input_type == 'text'">
              <BaseInput
                v-if="field.key.includes('amount')"
                :ref="field.key"
                v-model="newAsset[field.key]"
                v-money="amountMask"
                :disabled="disableInputsAssetDetail(field)"
                :rules="validateAmountField(field.required)"
                :label="getLabelFieldInput(field)"
                :qa-identification-name="`qa-id-name-${field.key}`"
              />
              <CnpjInput
                v-else-if="field.key.includes('tax-number')"
                v-model="newAsset[splitProp(field.key)[0]]['data'][splitProp(field.key)[1]]"
                :disabled="disableInputsAssetDetail(field)"
                :is-required="field.required === 'true'"
                :label="getLabelFieldInput(field)"
                :qa-identification-name="`qa-id-name-${field.key}`"
              />
              <CnpjInput
                v-else-if="field.key.includes('cnpj')"
                v-model="newAsset[field.key]"
                :disabled="disableInputsAssetDetail(field)"
                :is-required="field.required === 'true'"
                :label="getLabelFieldInput(field)"
                :qa-identification-name="`qa-id-name-${field.key}`"
              />
              <BaseInput
                v-else-if="(field.key == 'from.name' || field.key == 'to.name') && field.datasource == 'active_organization'"
                v-model="newAsset[splitProp(field.key)[0]][splitProp(field.key)[1]]"
                :disabled="disableInputsAssetDetail(field)"
                :rules="validateField(field.required)"
                :label="getLabelFieldInput(field)"
                :qa-identification-name="`qa-id-name-${field.key}`"
              />
              <BaseInput
                v-else-if="field.key == 'from.name' || field.key == 'to.name'"
                v-model="newAsset[splitProp(field.key)[0]][splitProp(field.key)[1]]"
                :disabled="disableInputsAssetDetail(field)"
                :rules="validateField(field.required)"
                :label="getLabelFieldInput(field)"
                :qa-identification-name="`qa-id-name-${field.key}`"
              />
              <BaseInput
                v-else
                v-model="newAsset[field.key]"
                :disabled="disableInputsAssetDetail(field)"
                :rules="validateField(field.required)"
                :label="getLabelFieldInput(field)"
                :qa-identification-name="`qa-id-name-${field.key}`"
              />
            </template>
            <template v-if="field.input_type == 'date'">
              <BaseDatePicker
                :name-placeholder="getLabelFieldInput(field)"
                :date-value="newAsset[field.key]"
                :max="null"
                locale="pt-br"
                time-zone="UTC"
                :disabled="disableInputsAssetDetail(field)"
                :rules="validateField(field.required)"
                :qa-id-name="`qa-id-name-${field.key}`"
                :qa-identification-name="`qa-id-name-${field.key}`"
                @on-input-date-picker="(value) => { updateFilter('newAsset', value, field.key) }"
              />
            </template>
            <template
              v-if="field.input_type == 'select' || field.input_type == 'multiselect'"
            >
              <v-autocomplete
                v-if="field.key == 'from.name' || field.key == 'to.name'"
                v-model="newAsset[splitProp(field.key)[0]].nameAndTaxnumber"
                :items="getOptionsList(field)"
                return-object
                item-text="nameAndTaxnumber"
                item-value="nameAndTaxnumber"
                :disabled="disableInputsAssetDetail(field)"
                :label="getLabelFieldInput(field)"
                class="app-show-hint mb-2"
                hint="Filtre por Razão Social ou CNPJ"
                persistent-hint
                :rules="validateField(field.required)"
                @change="changeTaxNumberField(splitProp(field.key)[0], newAsset[splitProp(field.key)[0]].nameAndTaxnumber.tax_number)"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.tax_number | maskCNPJ }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-else-if="field.key == 'trading_partner_name'"
                v-model="newAsset[field.key]"
                :items="getOptionsList(field)"
                return-object
                item-text="nameAndTaxnumber"
                item-value="id"
                :disabled="disableInputsAssetDetail(field)"
                :label="getLabelFieldInput(field)"
                class="app-show-hint"
                hint="Filtre por Razão Social ou CNPJ"
                persistent-hint
                :rules="validateField(field.required)"
                @change="newAsset['trading_partner_cnpj'] = $options.filters.maskCNPJ(newAsset[field.key].tax_number)"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.tax_number | maskCNPJ }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-select
                v-else-if="field.key == 'status.name' && contextActions === 'edit'"
                v-model="newAsset[splitProp(field.key)[0]][splitProp(field.key)[1]]"
                :items="getOptionsList(field)"
                item-text="name"
                item-value="name"
                :label="getLabelFieldInput(field)"
                :disabled="disableInputsAssetDetail(field)"
                :rules="validateField(field.required)"
                @change="hasChangedStatus = true"
              />
              <v-select
                v-else-if="field.key == 'status.name' && contextActions === 'create'"
                v-model="newAsset[field.key]"
                :items="getOptionsList(field)"
                item-text="name"
                item-value="name"
                :label="getLabelFieldInput(field)"
                :disabled="disableInputsAssetDetail(field)"
                :rules="validateField(field.required)"
                @change="hasChangedStatus = true"
              />
              <v-select
                v-else
                v-model="newAsset[field.key]"
                :items="getOptionsList(field)"
                item-text="name"
                item-value="value"
                :label="getLabelFieldInput(field)"
                :disabled="disableInputsAssetDetail(field)"
                :rules="validateField(field.required)"
              />
            </template>
            <template
              v-if="field.input_type == 'file'"
            >
              <div
                class="py-2 my-4 app-align-items-center"
                style="border-bottom: 1px solid #9e9e9e; padding-bottom: 5px;"
              >
                <p
                  class="color--text-black-087"
                  style="font-size: 16px;"
                >
                  {{ getLabelFieldInput(field) }}
                </p>
                <v-spacer />
                <v-file-input
                  v-show="false"
                  ref="inputDocument"
                  :rules="validateField(field.required)"
                  accept=".pdf"
                  :name="`qa-id-name-${field.key}`"
                  @change="onUpload"
                />
                <BaseButton
                  type="text"
                  color="blueff"
                  class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 ma-0"
                  style="height: unset!important"
                  :qa-identification-name="`qa-id-name-${field.key}`"
                  @click="selectDocument"
                >
                  Escolher arquivo
                  <v-progress-circular
                    v-if="isUploadingDocument"
                    indeterminate
                    class="app-button--is-loading mr-0 ml-2"
                  />
                  <v-icon
                    v-if="uploadDocument"
                    right
                  >
                    check_circle
                  </v-icon>
                </BaseButton>
              </div>
            </template>
          </div>
          <div
            v-if="programMovementDynamicAssetFields.length == 0"
            class="d-flex align-center justify-center text-center mb-4"
            style="height: 400px;"
          >
            Nenhum campo para exibir.
          </div>
        </template>

        <BaseDataTable
          v-if="disableActionButtons"
          :items="programMovementDynamicAssetFields"
          :hide-headers="true"
          :hide-actions="true"
          background-color="transparent"
          :options="{ page: 1, itemsPerPage: programMovementDynamicAssetFields.length + 1 }"
          class="create-asset-data-table"
        >
          <template v-slot:inject-items="scopedItems">
            <tr
              v-for="items in scopedItems"
              :key="items.index"
            >
              <td v-if="!isEditingAsset">
                {{ items.item.name }}
              </td>
              <td
                v-if="isAdminEditing && assetObject === null"
                class="text-right"
              >
                <span class="app-apply-blur">Valor {{ items.item.name }}</span>
              </td>
              <td
                v-else-if="items.item.hidden === 'true' && hideAllData"
                class="text-right"
              >
                {{ hideData(newAsset[items.item.key], items.item.hidden) }}
              </td>
              <td
                v-else
                class="text-right"
                :style="{ border: contextActions === 'create' || isEditingAsset ? 'none' : null }"
              >
                <p v-if="items.item.input_type == 'date'">
                  {{ newAsset[items.item.key] | getDate }}
                </p>
                <p v-else-if="items.item.key == 'status.name'">
                  {{ newAsset[splitProp(items.item.key)[0]][splitProp(items.item.key)[1]] || '-' }}
                </p>
                <p
                  v-else-if="items.item.key == 'nfe_key'"
                  class="app-align-items-center"
                  style="justify-content: flex-end;"
                >
                  {{ newAsset[items.item.key] || '-' }}
                  <BaseButton
                    v-if="newAsset[items.item.key]"
                    color="blueff"
                    type="text"
                    target="_blank"
                    :href="`http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&tipoConteudo=7PhJ+gAVw2g=&nfe=${newAsset[items.item.key]}`"
                    class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 mr-0"
                  >
                    Consultar
                  </BaseButton>
                </p>
                <p v-else>
                  {{
                    formatFields(items.item.key, newAsset) || '-'
                  }}
                </p>
              </td>
            </tr>
          </template>
        </BaseDataTable>
        <v-divider
          v-if="contextActions === 'edit' && hasPermissionToEditAsset() && !isEditingAsset && !isAdminEditing"
        />
        <v-row
          v-if="!isValidGrossAmount"
          dense
          justify="center"
          class="mt-2"
        >
          <v-col cols="auto">
            <p>
              O {{ getLabelGrossAmount() }} não pode ser menor que o {{ getLabelAmount() }}.
            </p>
          </v-col>
        </v-row>
        <v-row
          v-if="hasPermissionToEditAsset() && contextActions !== 'read' && !isAdminEditing"
          dense
          justify="end"
          class="mt-2 mx-3"
        >
          <v-col cols="auto">
            <BaseButton
              color="primary"
              type="text"
              :is-disabled="disableActionButtons"
              @click="cancelForm"
            >
              Cancelar
            </BaseButton>
            <BaseButton
              data-submit
              type="depressed"
              color="primary"
              :is-disabled="disableActionButtons || !formIsValid || !isValidGrossAmount"
              @click="sendAsset"
            >
              Salvar
            </BaseButton>
          </v-col>
        </v-row>
      </v-form>
      <BaseSnackbar
        :active-snackbar="feedback"
        :type-snackbar="feedbackColor"
        :text-snackbar="feedbackMessage"
        @closeSnackbar="feedback = !feedback"
      />
    </div>
  </BaseCard>
</template>

<script>
import BaseDataTable from '../../BaseDataTable'
import BaseDatePicker from '../../BaseDatePicker'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../../BaseSnackbar.vue'
import CnpjInput from '../../Input/CnpjInput.vue'
import MenuConfigurationSchemaField from '../../Platform/MenuConfigurationSchemaField'
import {amountMask} from '../../../directives/moneyMasks'
import { mapActions, mapState } from 'vuex'
import {utilsShowErrorInDevelopmentEnvironment, utilsTranformValueToOnlyNumber} from '../../../utils'
import ProgramRoles from "@/mixins/ProgramRoles";
import permissions from '@/mixins/permissions'

export default {
  components: { BaseDataTable, BaseDatePicker, BaseSnackbar, CnpjInput, MenuConfigurationSchemaField },
  mixins: [BaseSnackbarConfiguration, ProgramRoles, permissions],
  props: {
    contextActions: {
      required: true,
      type: String,
      validator: (value) => ['read', 'create', 'edit'].includes(value)
    },
    assetObject: {
      required: false,
      type: Object,
      default: ()=> {
        valid: false
      }
    },
    clearForm: {
      required: false,
      type: Boolean
    },
    scopeSchema: {
      type: String,
      required: false,
      default: ''
    },
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    registerAsset: {
      type: Boolean,
      required: false,
      default: false
    },
    headerTitle: {
      type: String,
      required: false,
      default: ""
    },
    headerTitleDescription: {
      type: String,
      required: false,
      default: ""
    },
    scopeCategory: {
      type: String,
      required: false,
      default: "assets"
    },
    scopeCategoryId: {
      type: String,
      required: false,
      default: 'asset'
    }
  },
  data() {
    return {
      formName: 'formCreateAsset',
      amountMask,
      temporaryAsset: {},
      formIsValid: false,
      newAsset: {
        from: {
          name: "",
          data: {
            'tax-number': ""
          }
        },
        to: {
          name: "",
          data: {
            'tax-number': ""
          }
        }
      },
      isEditingAsset: false,
      isUploadingDocument: false,
      uploadDocument: null,
      buyerOrganizationList: [],
      sellerOrganizationList: [],
      tradingPartnerOrganizationList: []
    }
  },
  computed: {
    ...mapState({
      programMovementDynamicAssetFields: state => state.programMovements.programMovementDynamicAssetFields,
      programMembers: state => state.programMembers.programMembers,
      suggestions: state => state.programFilters.suggestions,
      hideAllData: state => state.platformProgram.hideAllData
    }),
    isValidGrossAmount() {
      if(this.newAsset.amount && this.newAsset.gross_amount) {
        return this.parseNumberDecimal(this.newAsset.amount) <= this.parseNumberDecimal(this.newAsset.gross_amount) ? true : false
      } else {
        return true
      }
    },
    disableActionButtons(){
      return this.contextActions === 'read' || (this.contextActions === 'edit' && !this.isEditingAsset)
    }
  },
  watch: {
    assetObject: {
      handler() {
        this.setInitialData()
      },
      deep: true
    },
    newAsset: {
      handler() {
        if(this.getActiveOrganization()){
          this.programMovementDynamicAssetFields.find(field => {
            if(field.datasource === 'active_organization'){
              this.changeNameField(field.key, this.getActiveOrganization().name)
              this.changeTaxNumberField(this.splitProp(field.key)[0], this.getActiveOrganization().tax_number)
            }
          })
        }
      },
      deep: true
    },
    clearForm(){
      if(this.clearForm) {
        this.changeValueOfIsEditingAsset(false)
        this.cancelForm()

      }
    }
  },
  created(){
    this.setDateFields()
    this.setInitialData()
    this.temporaryAsset = {...this.newAsset}
  },
  methods: {
    ...mapActions('programMovements',['apiCreateMovement', 'apiPatchProgramMovements', 'apiUpdateProgramMovement']),
    ...mapActions(['apiGetProgramIntegrations', 'apiUploadIntegration', 'apiGetProgramFiles']),
    ...mapActions('programMembers', ['apiGetProgramMembers']),
    ...mapActions('programFilters', ['apiPostSuggestValues']),
    ...mapActions(['apiGetDownloadFile']),
    ...mapActions(['apiGetSchemasFields', 'apiUpdateSchemaField', 'apiCreateSchemaField']),
    ...mapActions('programMovements', ['apiGetSchemas']),
    async setInitialData(){
      if(this.assetObject){
        Object.keys(this.assetObject).every((prop) => {
          if(prop.includes('amount')){
            let splitObject = this.assetObject[prop].split('.')

            if(splitObject[splitObject.length-1].length == 1){
              this.assetObject[prop] += '0'
            }
          } else if(prop == 'to' || prop == 'from'){
            this.assetObject[prop].nameAndTaxnumber = `${this.assetObject[prop].name.toUpperCase()} - ${this.assetObject[prop].data['tax-number']}`
          }
           return prop
         })
        this.newAsset = JSON.parse(JSON.stringify(this.assetObject))
      }
      await this.apiPostSuggestValues({category: 'assets', key: 'status.name'})
      this.getMemberOrganizationList()
    },
    getLabelFieldInput(field){
      return field.required == "true" ? `${field.name} *` : field.name
    },
    getLabelAmount(){
      return this.programMovementDynamicAssetFields.filter(field => field.key === 'amount')[0].name || 'Valor líquido'
    },
    getLabelGrossAmount(){
      return this.programMovementDynamicAssetFields.filter(field => field.key === 'gross_amount')[0].name || 'Valor bruto'
    },
    validateField(value){
      if(value == "true")
      return [this.isValid]
    },
    validateAmountField(value){
      if(value == "true")
      return [this.isAmountValid]
    },
    isAmountValid(value) {
      return this.parseNumberDecimal(value) > 0 || 'Valor inválido'
    },
    hasPermissionToEditAsset(){
      return this.programMovementDynamicAssetFields.findIndex(field => field.read_only == "false") > -1 ? true : false
    },
    disableInputsAssetDetail(asset) {
      return asset.read_only == 'true'
    },
    getEditedFields(fields) {
      let fieldsString = ''
      fields.forEach(field =>
          field.read_only == 'false' && field.key != 'status.name' && field.key != 'date' ? fieldsString +=  `${field.keyWithPrefix},` : '')
      return fieldsString.replace(/,\s*$/, "");
    },
    editAsset() {
      this.changeValueOfIsEditingAsset(true)
    },
    moveToLogsAndInstallments(){
      document.getElementById('installmentsAndLogs').scrollIntoView({ behavior: 'smooth' });
    },
    splitProp(key) {
      let splittedKey = key.split('.')
      return splittedKey
    },
    updateFilter(field, value, attribute) {
      this[field][attribute] = value
    },
    changeTaxNumberField(key, value){
      this.$nextTick(() => {
        Object.assign(this.newAsset[key].data, {'tax-number': this.$options.filters.maskCPForCNPJ(value)})
      })
    },
    changeNameField(key, value){
      this.$nextTick(() => {
        Object.assign(this.newAsset[this.splitProp(key)[0]], {
          ...this.newAsset[this.splitProp(key)[0]],
          [this.splitProp(key)[1]]: value
        })
      })
    },
    formatFields(assetKey, assetDetail) {
      const handlers = {
        'from.name': () => this.returnValueOfNestedField(assetKey, assetDetail),
        'to.name': () => this.returnValueOfNestedField(assetKey, assetDetail),
        'type.title': () => this.returnValueOfNestedField(assetKey, assetDetail),
        'from.tax-number': () => this.returnValueOfTaxNumberField(assetKey, assetDetail),
        'to.tax-number': () => this.returnValueOfTaxNumberField(assetKey, assetDetail)
      }
      const handler = handlers[assetKey]

      if(handlers[assetKey]) {
        return handler()
      }

      return this.formatCurrencyIfNecessary(assetKey, assetDetail)
    },
    returnValueOfNestedField(assetKey, assetDetail) {
      return assetDetail[this.splitProp(assetKey)[0]][this.splitProp(assetKey)[1]]
    },
    returnValueOfTaxNumberField(assetKey, assetDetail) {
      return this.$options.filters.maskCNPJ(assetDetail[this.splitProp(assetKey)[0]]['data'][this.splitProp(assetKey)[1]])
    },
    formatCurrencyIfNecessary(assetKey, assetDetail){
      return assetKey.includes('amount') && assetDetail[assetKey] && !assetDetail[assetKey].includes(',') ? this.$options.filters.formatCurrency(assetDetail[assetKey]) : assetDetail[assetKey]
    },
    selectDocument() {
      const inputDocument = this.$refs.inputDocument
      if(inputDocument && Array.isArray(inputDocument)){
        inputDocument[0].$el.querySelector('input').click()
      } else if(inputDocument) {
        inputDocument.$el.querySelector('input').click()
      }
    },
    setDateFields() {
      this.programMovementDynamicAssetFields.forEach(field => {
        if(field.input_type == 'date') {
          this.newAsset = {...this.newAsset, [field.key]: '' }
        }
      })
    },
    cancelForm() {
      this.clearFields()
      this.newAsset = this.assetObject ? JSON.parse(JSON.stringify(this.assetObject)) : {...this.temporaryAsset}
      this.changeValueOfIsEditingAsset(false)
    },
    changeValueOfIsEditingAsset(value) {
      if (typeof value != 'boolean') {
        throw new Error(`Value must be Boolean`)
      } else {
        this.isEditingAsset = value
      }
    },
    clearFields() {
      this.$refs[this.formName].reset()
      this.isUploadingDocument = false
      this.uploadDocument = null
      this.clearDateFields()
      this.clearAmountFields()
    },
    clearDateFields() {
      this.programMovementDynamicAssetFields.forEach(field => {
        if(field.input_type == 'date') {
          this.newAsset[field.key] = ''
        }
      })
    },
    clearAmountFields() {
      this.programMovementDynamicAssetFields.forEach(field => {
        if(field.key.includes('amount'))
          this.forceResetInputValue(this, field.key)
      })
    },
    async getMemberOrganizationList() {
      await this.apiGetProgramMembers()
      this.programMembers.forEach(member => {
        let newMember = member.organization
        newMember.nameAndTaxnumber = `${newMember.name.toUpperCase()} - ${newMember.tax_number}`
        if(member.role == 'buyer')
          this.buyerOrganizationList.push(newMember)
        else if(member.role == 'seller')
          this.sellerOrganizationList.push(newMember)
        else if(member.role == 'trading-partner')
          this.tradingPartnerOrganizationList.push(newMember)
      })
    },
    getOptionsList(field){
      let optionsList = {
        "/v1/members?role=buyer": this.buyerOrganizationList,
        "/v1/members?role=seller": this.sellerOrganizationList,
        "/v1/members?role=trading-partner": this.tradingPartnerOrganizationList,
        "/v1/programs/roles": this.getRoleOrganizations(),
        "/v1/movements/assets/suggestions/values": this.suggestions
      }
      if(field.datasource.startsWith('[')){
        return JSON.parse(field.datasource)
      } else {
        return optionsList[field.datasource]
      }
    },
    async sendAsset() {
      let assetObject = {}
      let data = {}
      const feedbackMsg = {
        "create": ["Ativo enviado. Confira o resultado na lista de ativos disponíveis.", "Ocorreu um erro ao cadastrar o ativo.", "info"],
        "edit": ["Ativo editado com sucesso!", "Erro ao editar o ativo.", "success"],
      }

      this.programMovementDynamicAssetFields.forEach(({key}) => {
        if(key.includes('from.') || key.includes('to.')) {
          assetObject = {
            ...assetObject,
            from: {
              name: this.newAsset.from.nameAndTaxnumber && this.newAsset.from.nameAndTaxnumber.name ? this.newAsset.from.nameAndTaxnumber.name : this.newAsset.from.name,
              data: {
                type: "seller",
                'tax-number': utilsTranformValueToOnlyNumber(this.newAsset.from.data['tax-number']) || ''
              }
            },
            to: {
              name: this.newAsset.to.nameAndTaxnumber && this.newAsset.to.nameAndTaxnumber.name ? this.newAsset.to.nameAndTaxnumber.name : this.newAsset.to.name,
              data: {
                type: "buyer",
                'tax-number': utilsTranformValueToOnlyNumber(this.newAsset.to.data['tax-number']) || ''
              }
            }
          }
        } else if(key.includes('amount')){
          assetObject = {
            ...assetObject,
            [key]: this.newAsset[key] == undefined ? '' : this.parseNumberDecimal(this.newAsset[key]).toFixed(2)
          }
        } else if(key === 'status.name'){
          assetObject = {
            ...assetObject,
            [this.splitProp(key)[0]]: this.newAsset[this.splitProp(key)[0]][this.splitProp(key)[1]] == undefined ? '' : { name: this.newAsset[this.splitProp(key)[0]].name }
          }
        } else if(key.includes('origin')){
          assetObject = {
            ...assetObject,
            [key]: this.uploadDocument.uuid
          }
        } else if(key.includes('trading_partner_name')){
          assetObject = {
            ...assetObject,
            [key]: this.newAsset[key].name || ''
          }
        } else if(key.includes('trading_partner_cnpj')){
          assetObject = {
            ...assetObject,
            [key]: utilsTranformValueToOnlyNumber(this.newAsset[key]) || ''
          }
        } else {
          assetObject = {...assetObject, [key]: this.newAsset[key] == undefined ? '' : this.newAsset[key] }
        }
      })

      const {type, from, to, date, status, ...payload} = assetObject

      if(this.contextActions === 'edit'){
        data = {
          type: this.scopeCategory,
          scope: this.scopeSchema,
          id: this.newAsset.id,
          fields: this.getEditedFields(this.programMovementDynamicAssetFields),
          data: {
            id: this.scopeCategoryId,
            type,
            data: {
              ...payload,
              'external-id': this.newAsset['external-id'],
              date: this.newAsset['date']
            },
            from,
            to
          }
        }
      } else {
        data = {
          type: {
            id: this.scopeCategoryId,
            sub_type: "invoices",
            'external-type': "Fatura",
            title: "Fatura"
          },
          from,
          to,
          data: {
            ...payload,
            'external-id': this.newAsset.number || '',
            date: date
          }
        }
      }

      try {
        if(this.hasChangedStatus){
           const movementPayload = {
            action: 'change-status',
            data: {
              id: this.newAsset.id,
              date: this.newAsset['date'],
              'external-id': this.newAsset['external-id'],
              status: status
            }
          }
          await this.apiPatchProgramMovements({category: this.scopeCategory, data: movementPayload})
        }
        if(this.contextActions === 'edit'){
          if(data.fields.length > 0){
            await this.apiUpdateProgramMovement(data)
          }
        } else {
          await this.apiCreateMovement({type: this.scopeCategory, data})
        }

        this.showFeedback({
          message: `${feedbackMsg[this.contextActions][0]}`,
          color: feedbackMsg[this.contextActions][2]
        })

      }catch(err) {
        utilsShowErrorInDevelopmentEnvironment(err)
        this.showFeedback({
            message: `${feedbackMsg[this.contextActions][1]}`,
            color: 'error'
          })
      }finally {
        if(this.contextActions === 'create'){
          this.clearFields()
          this.clearAmountFields()
          this.setInitialData()
        } else {
          this.$emit("updateAssetList")
          this.newAsset.from.name = this.newAsset.from.nameAndTaxnumber.name || this.newAsset.from.name
          this.newAsset.to.name = this.newAsset.to.nameAndTaxnumber.name || this.newAsset.to.name
          if(this.newAsset['trading_partner_name']){
            this.newAsset['trading_partner_name'] = this.newAsset['trading_partner_name'].name
          }
          this.changeValueOfIsEditingAsset(false)
        }
      }
    },
    async onUpload(file) {
      this.isUploadingDocument = true

      if(file) {
        let newDocumentName = `${new Date().getTime()}-${file.name}`
        try{
          let formData = new FormData()
          let uploadedFiles = []
          let self = this
          let counter = 0

          formData.append('file', file, newDocumentName)
          await this.apiUploadIntegration({id: 'asset', type: 'upload', formData})

          let callApiInterval = setInterval(async () => {
            if(++counter == 5) {
              clearInterval(callApiInterval)
              this.showFeedback({
                message: 'Erro ao enviar arquivo.',
                color: 'error'
              })
            }
            uploadedFiles = await self.apiGetProgramFiles({
                startDate: new Date().toISOString().substr(0, 10),
                endDate: new Date().toISOString().substr(0, 10),
                page: 1,
                size: 10,
                prefix: 'uploaded-files'
              }
            )
            self.uploadDocument = uploadedFiles.find(({fileName}) => {
              if(fileName === newDocumentName){
                clearInterval(callApiInterval)
                self.isUploadingDocument = false
                return fileName
              }
            })
          }, 3000)

        }catch(err) {
          utilsShowErrorInDevelopmentEnvironment(err)
          this.showFeedback({
            message: 'Erro ao enviar arquivo.',
            color: 'error'
          })
        }
      }
    },
    downloadFile(payload) {
      this.apiGetDownloadFile(payload)
    },
    async removeItemSchema({ field, role, schemaName, programName }) {
      const payloadList = this.programMovementDynamicAssetFields.filter(elem => elem.key !== field.key)
      try {
        await this.apiUpdateSchemaField({
          name: schemaName,
          scope: this.scopeSchema,
          fields: payloadList,
          role: role,
          program: programName
        })
        this.showFeedback({
          message: 'Campo removido com sucesso!',
          color: 'success'
        })
      } catch (error) {
        this.showFeedback({
          message: 'Erro ao remover campo.',
          color: 'error'
        })
      } finally {
        await this.apiGetSchemasFields()
        await this.apiGetSchemas({ scope: this.scopeSchema, role })
      }
    },
    addOrEditItemSchema({key, role, schemaName, programName, data }){
      const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: [],
        role,
        program: programName
      }

      if(this.programMovementDynamicAssetFields.length) {
        const payloadList = this.programMovementDynamicAssetFields.filter(field => field.key !== key)
        payloadList.push(data)

        Object.assign(payload, {
          ...payload,
          fields: payloadList
        })
        this.updateSchema(payload)
      } else {
        Object.assign(payload, {
          ...payload,
          fields: [data]
        })
        this.createSchema(payload)
      }
    },
    async createSchema(payload) {
      try {
        await this.apiCreateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao criar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: this.scopeSchema, role: payload.role })
      }
    },
    async updateSchema(payload){
      try {
        await this.apiUpdateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao atualizar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: this.scopeSchema, role: payload.role })
      }
    },
    changeOrderItemSchema({ role, schemaName, programName, data }){
        const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: data,
        role,
        program: programName
      }
      this.updateSchema(payload)
    }
  }
}
</script>

<style lang="scss">
.create-asset-data-table {
  .v-input {
    padding: 18px 25px 0px 15px;
  }
  .v-text-field__details {
    display: none;
  }
  .app-show-hint {
    .v-input__control {
      .v-text-field__details {
        display: block!important;
      }
    }
  }
}
.header-table {
  width: 100%;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--custom-theme-black-005);
  border-bottom: 1px solid var(--custom-theme-black-020);
}
</style>
