import { render, staticRenderFns } from "./ConfigurationTradeSchemaTable.vue?vue&type=template&id=652357bd&scoped=true"
import script from "./ConfigurationTradeSchemaTable.vue?vue&type=script&lang=js"
export * from "./ConfigurationTradeSchemaTable.vue?vue&type=script&lang=js"
import style0 from "./ConfigurationTradeSchemaTable.vue?vue&type=style&index=0&id=652357bd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652357bd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VDivider,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
