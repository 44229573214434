import axios from 'axios'
import router from '../router'
import store from '../store'
import loader from '../global/loader'

const api = axios.create({
  baseURL : process.env.VUE_APP_ROOT_API
})

api.interceptors.request.use(req => {
  loader.startProgressLoadPage()
  return req
}, error => {
  throw error
})

api.interceptors.response.use(res => {
  loader.stopProgressLoadPage()
  return res
}, error => {
     let payload = {
        active: true,
        msg: error.response.data.messages ? error.response.data.messages[0] : 'Você não tem permissão para acessar esse recurso.'
      }
    switch(error.response.status){
      case 500:
        loader.stopProgressLoadPage()
        store.commit("setDisableNavigation", true);
        router.push('/500')
      break
      case 300:
        store.commit("setProgramRoles", error.response.data.roles)
        router.push('/assume-role').catch(()=>{})
      break
      case 403:
        store.commit("setPermissionErrorMessage", payload)
        throw error
      case 401:
        store.commit("setPermissionErrorMessage", payload)
        throw error
      default:
        throw error
    }
})


export default api
