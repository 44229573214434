<template>
  <div class="platform-personal-data">
    <BaseContainer
      :active-spacer="true"
      :permission-list="['sales']"
    >
      <template v-slot:header-title>
        Clientes
      </template>
      <template v-slot:header-title-description>
        Envie convites para novos clientes.
      </template>
      <v-container px-0>
        <v-form
          :ref="customerFormName"
          v-model="customerValid"
        >
          <v-row>
            <h3>Dados da empresa</h3>
          </v-row>
          <v-row
            dense
            align="center"
          >
            <v-col
              cols="auto"
              sm="4"
            >
              <BaseInput
                v-model="customer.account.name"
                :rules="[isValid]"
                label="Nome*"
                qa-identification-name="qa-id-name-customer-name"
              />
            </v-col>

            <v-col
              cols="auto"
              sm="4"
            >
              <EmailInput
                ref="email-customer"
                v-model="customer.account.email"
                qa-identification-name="qa-id-name-customer-email"
                label="E-mail*"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            align="center"
          >
            <v-col
              cols="auto"
              sm="3"
            >
              <BaseInput
                v-model="customer.organization.business_name"
                :rules="[isValid]"
                label="Razão Social*"
                qa-identification-name="qa-id-name-customer-name-org"
              />
            </v-col>

            <v-col
              cols="auto"
              sm="3"
            >
              <CnpjInput
                v-model="customer.organization.tax_number"
                label="CNPJ*"
                qa-identification-name="qa-id-name-organization-tax-number"
              />
            </v-col>
            <v-col
              cols="auto"
              sm="2"
            >
              <div
                class="app-align-items-center"
                style="border-bottom: 1px solid #9e9e9e; padding-bottom: 5px;"
              >
                <p
                  class="color--text-black-087"
                  style="font-size: 16px;"
                >
                  Proposta
                </p>
                <v-spacer />
                <v-file-input
                  v-show="false"
                  ref="inputDocument"
                  v-model="documentFile"
                  :rules="[isValid]"
                  accept=".pdf"
                  :name="`qa-id-name-pdf`"
                  @change="onUpload"
                />
                <BaseButton
                  type="text"
                  color="blueff"
                  class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 ma-0"
                  style="height: unset!important"
                  :qa-identification-name="`qa-id-name-document`"
                  @click="selectDocument"
                >
                  Escolher arquivo
                  <v-progress-circular
                    v-if="isUploadingDocument"
                    indeterminate
                    class="app-button--is-loading mr-0 ml-2"
                  />
                  <v-icon
                    v-if="uploadDocument"
                    right
                  >
                    check_circle
                  </v-icon>
                </BaseButton>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <v-form
          :ref="signerFormName"
          v-model="signerValid"
        >
          <v-row
            class="mt-4"
          >
            <h3>Dados do(s) assinante(s)</h3>
          </v-row>
          <v-row
            dense
            align="center"
            class="mb-6"
          >
            <v-col
              cols="auto"
              sm="3"
            >
              <BaseInput
                v-model="signer.name"
                :rules="[isValid]"
                label="Nome*"
                qa-identification-name="qa-id-name-customer-name-org"
              />
            </v-col>

            <v-col
              cols="auto"
              sm="2"
            >
              <BaseInput
                ref="signerTaxNumberPerson"
                v-model="signer.identification_number"
                label="CPF*"
                :rules="[isValid, isValidLength]"
                qa-identification-name="qa-id-name-signer-tax-number"
                mask="###.###.###-##"
                ref-name="signerTaxNumberPerson"
              />
            </v-col>
            <v-col
              cols="auto"
              sm="2"
            >
              <EmailInput
                ref="email-signer"
                v-model="signer.email"
                qa-identification-name="qa-id-name-signer-email"
                label="E-mail*"
              />
            </v-col>
            <v-col
              cols="auto"
              sm="1"
              class="text-right app-align-items-center"
              style="justify-content: flex-end;"
            >
              <BaseButton
                type="text"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                :is-disabled="!signerValid"
                @click="addSigner(signer)"
              >
                Adicionar
              </BaseButton>
            </v-col>
          </v-row>
          <v-row
            dense
            class="mb-6"
          >
            <v-col
              cols="auto"
              sm="8"
              class="pa-0"
            >
              <template v-if="customer.organization.signers.length">
                <BaseDataTable
                  :headers="['', '', '', '']"
                  :items="customer.organization.signers"
                  :hide-actions="true"
                  :hide-headers="true"
                  background-color="transparent"
                >
                  <template v-slot:inject-items="scopedItems">
                    <tr
                      v-for="value in scopedItems"
                      :key="value.index"
                    >
                      <td>
                        {{ value.item.name }}
                      </td>
                      <td>
                        {{ value.item.identification_number | maskCPForCNPJ }}
                      </td>
                      <td>
                        {{ value.item.email }}
                      </td>
                      <td
                        class="text-right app-align-items-center"
                        style="justify-content: flex-end; min-width: 130px;"
                      >
                        <BaseButton
                          type="text"
                          class="app-button__link-text app-button--hide-ripple app-button--no-hover pa-0 ma-0"
                          @click="deleteSigner(value.index)"
                        >
                          Apagar
                        </BaseButton>
                      </td>
                    </tr>
                  </template>
                </BaseDataTable>
              </template>
            </v-col>
          </v-row>
        </v-form>
        <v-row
          dense
          class="my-8"
        >
          <v-col
            cols="auto"
            sm="8"
          >
            <BaseButton
              data-submit
              type="depressed"
              qa-identification-name="qa-id-name-customer-confirm"
              color="primary"
              :is-disabled="!customerValid || customer.organization.signers.length == 0"
              class="ml-0"
              @click="inviteCustomer()"
            >
              Convidar
            </BaseButton>
            <BaseButton
              color="primary"
              type="text"
              qa-identification-name="qa-id-name-customer-cancel"
              @click="clearForm"
            >
              Cancelar
            </BaseButton>
          </v-col>
        </v-row>
      </v-container>
      <BaseSnackbar
        :active-snackbar="feedback"
        :type-snackbar="feedbackColor"
        :text-snackbar="feedbackMessage"
        @closeSnackbar="feedback = !feedback"
      />
    </BaseContainer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CnpjInput from '../Input/CnpjInput'
import EmailInput from '../Input/EmailInput'
import { utilsIsValidLength, utilsTranformValueToOnlyNumber } from '../../utils'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../BaseSnackbar'
import BaseDataTable from '../BaseDataTable'

export default {
  components: { CnpjInput, EmailInput, BaseSnackbar, BaseDataTable },
  mixins: [BaseSnackbarConfiguration],
  data() {
    return {
      customer: {
        account: {
          name: '',
          email: ''
        },
        organization: {
          tax_number: '',
          business_name: '',
          signers: [],
        }
      },
      customerValid: false,
      signer: {},
      signerValid: false,
      customerFormName: 'customerForm',
      signerFormName: 'signerForm',
      isUploadingDocument: false,
      uploadDocument: false,
      document_uuid: null,
      documentFile: null
    }
  },
  methods: {
    ...mapActions('platformOrganization',['apiUploadDocument', 'apiInviteCustomer']),
    isValidLength(value) {
      const transformedValue = utilsTranformValueToOnlyNumber(value || '')

      return utilsIsValidLength(transformedValue, 11) || 'CPF inválido'
    },
    addSigner(signer) {
      signer.identification_number = utilsTranformValueToOnlyNumber(signer.identification_number)
      this.customer.organization.signers.push(signer)
      this.signer = {
        name: '',
        email: '',
        identification_number: ''
      }
    },
    deleteSigner(index) {
      this.customer.organization.signers.splice(index, 1)
    },
    selectDocument() {
      const inputDocument = this.$refs.inputDocument
      if(inputDocument && Array.isArray(inputDocument)){
        inputDocument[0].$el.querySelector('input').click()
      } else if(inputDocument) {
        inputDocument.$el.querySelector('input').click()
      }
    },
    async onUpload(file) {
      this.isUploadingDocument = true

      if(file) {
        try{
          let formData = new FormData()

          formData.append('file', file, file.name)
          this.document_uuid = await this.apiUploadDocument({organizationId: 'sales', type: 'upload', formData})
          this.uploadDocument=true
          this.showFeedback({
            message: 'Documento enviado com sucesso.',
            color: 'success'
          })
        }catch(err) {
          this.uploadDocument=false
          this.showFeedback({
            message: 'Erro ao enviar documento.',
            color: 'error'
          })
        } finally {
          this.isUploadingDocument=false
        }
      }
    },
    async inviteCustomer(){
      const payload = {
        ...this.customer,
        organization: {
          ...this.customer.organization,
          tax_number: utilsTranformValueToOnlyNumber(this.customer.organization.tax_number)
        },
        document_uuid: this.document_uuid.replace("storage://", "")
      }
      try {
        await this.apiInviteCustomer(payload)
        this.showFeedback({
          message: 'Convite enviado com sucesso.',
          color: 'success'
        })
        this.clearForm()
      } catch(e){
        this.showFeedback({
          message: 'Erro ao convidar cliente.',
          color: 'error'
        })
      }
    },
    clearForm() {
      this.cancelForm(this, this.customerFormName)
      this.cancelForm(this, this.signerFormName)
      this.uploadDocument = false
      this.isUploadingDocument = false
      this.document_uuid = null
      this.documentFile = null
      Object.assign(this.customer, {
        account: {
          name: '',
          email: ''
        },
        organization: {
          tax_number: '',
          business_name: '',
          signers: [],
        }
      })
    }
  }
}
</script>

<style>
  .base-card-title-medium {
    font-size: 22px !important;
  }
</style>
